import React, { useRef, useState, useEffect, useCallback } from 'react';
import MessageContainer from './components/MessageContainer';
import ControlPanel from './components/ControlPanel';
import InputForm from './components/InputForm';
import ErrorPopup from './components/ErrorPopup';
import useAgents from './hooks/useAgents';
import useConversations from './hooks/useConversations';
import useAutoChat from './hooks/useAutoChat';
import useProjects from './hooks/useProjects';
import Header from './components/Header';
import Dashboard from './components/Dashboard';
import useDashboard from './hooks/useDashboard';

function Chat({isLoggedIn, user, handleSignIn, handleLogOut }) {
  const [isThreadListVisible, setIsThreadListVisible] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const [error, setError] = useState(null);
  const [isCreatingNewThread, setIsCreatingNewThread] = useState(false);
  const [workbenchContent, setWorkbenchContent] = useState(null);
  const threadContainerRef = useRef(null);
  const [isRenamingThread, setIsRenamingThread] = useState(false);
  const [workbenchVisible, setWorkbenchVisible] = useState(false);
  const [isAddingAgent, setIsAddingAgent] = useState(false);
  const [showDashboard, setShowDashboard] = useState(true);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [editingAgent, setEditingAgent] = useState(null);
  const quickStartInputRef = useRef(null);

  const handleResize = useCallback(() => {
    setIsSmallScreen(window.innerWidth < 768);
  }, []);

  const handleClickOutside = useCallback((event) => {
    if (isSmallScreen) {
      const isClickInsideThread = threadContainerRef.current && threadContainerRef.current.contains(event.target);
      const isClickOnMenuIcon = event.target.closest('[aria-label="Toggle thread list"]');

      if (!isClickInsideThread && !isClickOnMenuIcon && isThreadListVisible) {
        setIsThreadListVisible(false);
      }
    }
  }, [isSmallScreen, isThreadListVisible]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleResize, handleClickOutside]);

  useEffect(() => {
    // Find existing viewport meta tag or create a new one
    let viewportMeta = document.querySelector('meta[name="viewport"]');
    if (!viewportMeta) {
      viewportMeta = document.createElement('meta');
      viewportMeta.name = 'viewport';
      document.head.appendChild(viewportMeta);
    }

    // Set viewport properties to prevent zooming
    viewportMeta.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0';

    // Cleanup function to restore original viewport settings
    return () => {
      viewportMeta.content = 'width=device-width, initial-scale=1';
    };
  }, []);

  const {
    projects,
    selectedProjectId,
    isSharingProject,
    selectProject,
    createProject,
    updateProject,
    deleteProject,
    shareProject
  } = useProjects();

  const {
    threads,
    selectedThreadId,
    setSelectedThreadId,
    currentThreadTitle,
    setCurrentThreadTitle,
    moreThreadsAvailable,
    loadMoreThreads,
    createThread,
    deleteThread,
    renameThread,
    messages,
    setMessages,
    handleGetConversationData,
    handleAddConversationDataSource,
    handleRemoveConversationDataSource,
    fetchAgentsForThread,
    handleThreadSelect,
    activeAgents,
    setActiveAgents,
    conversationData,
    isUpdatingConversationDataSource,
    isLoadingConversation,
    loadMoreMessages,
    hasMoreMessages,
    isDeletingThread,
    isLoadingThreads,
  } = useConversations({ selectedProjectId });

  const handleCreateNewThread = useCallback(async () => {
    setIsCreatingNewThread(true);
    try {
      await createThread();
      setShowDashboard(false); // Hide dashboard when creating new thread
    } catch (error) {
      setError(error.message);
    } finally {
      setIsCreatingNewThread(false);
    }
  }, [createThread]);

  useEffect(() => {
    if (selectedThreadId) {
      const currentThread = threads.find(thread => thread.id === selectedThreadId);
      setCurrentThreadTitle(currentThread ? currentThread.title : '');
    } else {
      setCurrentThreadTitle('');
    }
  }, [selectedThreadId, threads]);

  const {
    inputValue,
    setInputValue,
    handleSendMessage,
    suggestions,
    setSuggestions,
    selectedAgents,
    setSelectedAgents,
    maxRounds,
    setMaxRounds,
    isMessageLoading,
    invokeAgents,
    abortChat
  } = useAutoChat({
    selectedProjectId,
    selectedThreadId,
    setMessages,
    setWorkbenchContent,
    activeAgents,
    user
  });

  const {
    availableAgents,
    agentData,
    addAgentToConversation,
    removeAgentFromConversation,
    createAgent,
    deleteAgent,
    updateAgent,
    resetDefaultAgent,
    getAgentData,
    addAgentDataSource,
    removeAgentDataSource,
    refreshAgents,
    isLoadingAgents,
    isCreatingAgent
  } = useAgents({
    selectedProjectId,
    selectedThreadId,
    invokeAgents,
    activeAgents,
    setActiveAgents,
    fetchAgentsForThread
  });

  useEffect(() => {
    // Only update localStorage when a project/thread is selected
    if (selectedProjectId && selectedThreadId) {
      localStorage.setItem('lastSelectedProject', selectedProjectId);
      localStorage.setItem('lastSelectedThread', selectedThreadId);
    }
    // Don't automatically hide dashboard based on these values
  }, [selectedProjectId, selectedThreadId]);

  // Modify handleProjectSelect to handle both project and thread selection
  const handleProjectSelect = async (projectId, threadId = null) => {
    try {
      await selectProject(projectId);
      
      if (threadId) {
        await handleThreadSelect(threadId);
        setShowDashboard(false); // Only hide dashboard when both project and thread are selected
      }
      // If no threadId, keep showing dashboard
    } catch (error) {
      setError(error.message);
    }
  };

  const {
    handleQuickStart,
  } = useDashboard({
    selectedProjectId,
    handleSendMessage,
    onSelectProject: handleProjectSelect,
    createProject,
    setShowDashboard,
    handleCreateNewThread,
    setWorkbenchContent,
    setWorkbenchVisible,
    setIsSidebarCollapsed,
  });

  return (
    <div className={`flex flex-col h-screen ${showDashboard ? 'bg-gray-900' : 'bg-gray-800'} text-white overflow-hidden`}>
      <Header 
        isLoggedIn={isLoggedIn}
        user={user}
        handleLogOut={handleLogOut}
        showDashboard={showDashboard}
        setShowDashboard={setShowDashboard}
        selectedProjectId={selectedProjectId}
        projects={projects}
        isSmallScreen={isSmallScreen}
        isSidebarCollapsed={isSidebarCollapsed}
        setIsCollapsed={setIsSidebarCollapsed}
      />

      <main className={`flex flex-1 overflow-hidden ${isSmallScreen ? 'pt-[50px]' : 'pt-[60px]'}`}>
        {!showDashboard && (
          <ControlPanel
            ref={threadContainerRef}
            threads={threads}
            selectedThreadId={selectedThreadId}
            handleThreadSelect={handleThreadSelect}
            loadMoreThreads={loadMoreThreads}
            moreThreadsAvailable={moreThreadsAvailable}
            deleteThread={deleteThread}
            renameThread={renameThread}
            isLoadingThreads={isLoadingThreads}
            isRenamingThread={isRenamingThread}
            selectedProjectId={selectedProjectId}
            activeAgents={activeAgents}
            availableAgents={availableAgents}
            agentData={agentData}
            addAgentToConversation={addAgentToConversation}
            updateAgent={updateAgent}
            deleteAgent={deleteAgent}
            resetDefaultAgent={resetDefaultAgent}
            removeAgentFromConversation={removeAgentFromConversation}
            createAgent={createAgent}
            getAgentData={getAgentData}
            addAgentDataSource={addAgentDataSource}
            removeAgentDataSource={removeAgentDataSource}
            refreshAgents={refreshAgents}
            isLoadingAgents={isLoadingAgents}
            isAddingAgent={isAddingAgent}
            setIsAddingAgent={setIsAddingAgent}
            isCollapsed={isSidebarCollapsed}
            setIsCollapsed={setIsSidebarCollapsed}
            isSmallScreen={isSmallScreen}
            editingAgent={editingAgent}
            setEditingAgent={setEditingAgent}
            showShareDialog={showShareDialog}
            setShowShareDialog={setShowShareDialog}
            shareProject={shareProject}
            isSharingProject={isSharingProject}
            createNewThread={handleCreateNewThread}
            isCreatingNewThread={isCreatingNewThread}
            className="bg-gray-800"
          />
        )}

        <section className={`chat-section flex-1 flex flex-col h-full overflow-hidden transition-all duration-300 ease-in-out 
          ${showDashboard ? 'w-full' : ''} 
          ${!isSmallScreen && !workbenchVisible && isSidebarCollapsed ? 'max-w-[60%] mx-auto' : ''}
          ${isSmallScreen ? 'max-w-full' : ''}
          bg-gray-800`}
        >
          {showDashboard ? (
            <Dashboard 
              onSelectProject={handleProjectSelect}
              projects={projects}
              threads={threads}
              isCreatingNewThread={isCreatingNewThread}
              selectedProjectId={selectedProjectId}
              user={user}
              handleLogOut={handleLogOut}
              agents={availableAgents}
              handleQuickStart={handleQuickStart}
              inputValue={inputValue}
              setInputValue={setInputValue}
              setShowDashboard={setShowDashboard}
              createProject={createProject}
              updateProject={updateProject}
              deleteProject={deleteProject}
              createAgent={createAgent}
              updateAgent={updateAgent}
              deleteAgent={deleteAgent}
              addDataSource={addAgentDataSource}
              removeDataSource={removeAgentDataSource}
              resetAgent={resetDefaultAgent}
              agentData={agentData}
              shareProject={shareProject}
              inputRef={quickStartInputRef}
              setIsSidebarCollapsed={setIsSidebarCollapsed}
              deleteThread={deleteThread}
              isDeletingThread={isDeletingThread}
              isSmallScreen={isSmallScreen}
            />
          ) : (
            <>
              <MessageContainer
                  selectedProjectId={selectedProjectId}
                  selectedThreadId={selectedThreadId}
                  messages={messages}
                  isMessageLoading={isMessageLoading}
                  isLoadingConversation={isLoadingConversation}
                  isSmallScreen={isSmallScreen}
                  setWorkbenchContent={setWorkbenchContent}
                  workbenchContent={workbenchContent}
                  handleSendMessage={handleSendMessage}
                  onLoadMoreMessages={loadMoreMessages}
                  hasMoreMessages={hasMoreMessages}
                  isLoadingThreads={isLoadingThreads}
                  workbenchVisible={workbenchVisible}
                  setWorkbenchVisible={setWorkbenchVisible}
                  isAddingAgent={isAddingAgent}
               />
              {(!isSmallScreen || !isThreadListVisible) && !(workbenchVisible && isSmallScreen) && (
                <InputForm
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  handleSendMessage={handleSendMessage}
                  suggestions={suggestions}
                  setSuggestions={setSuggestions}
                  selectedAgents={selectedAgents}
                  setSelectedAgents={setSelectedAgents}
                  activeAgents={activeAgents}
                  maxRounds={maxRounds}
                  setMaxRounds={setMaxRounds}
                  selectedThreadId={selectedThreadId}
                  handleAddConversationDataSource={handleAddConversationDataSource}
                  handleGetConversationData={handleGetConversationData}
                  handleRemoveConversationDataSource={handleRemoveConversationDataSource}
                  conversationData={conversationData}
                  isMessageLoading={isMessageLoading}
                  createThread={createThread}
                  isCreatingNewThread={isCreatingNewThread}
                  selectedProjectId={selectedProjectId}
                  abortChat={abortChat}
                  messages={messages}
                  isAddingAgent={isAddingAgent}
                  quickStart={false}
                  placeholder="Continue the conversation..."
                  disabled={isMessageLoading || isLoadingConversation}
                  setIsSidebarCollapsed={setIsSidebarCollapsed}
                />
              )}
            </>
          )}
        </section>
      </main>

      {error && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-70 flex justify-center items-center z-50">
          <ErrorPopup message={error} onClose={() => setError(null)} />
        </div>
      )}
    </div>
  );
}

export default Chat;
