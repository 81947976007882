import React from 'react';
import { Edit } from 'lucide-react';
import { motion } from 'framer-motion';
import { CircuitBackground } from '../../utils/styledComponents';
import Button from '../ui/Button';
import { SimpleAgentIcon } from '../ui/StaticIcons';

const AGENT_THEMES = [
  { border: 'border-cyan-500/30', text: 'text-cyan-100', accent: 'text-cyan-400', bg: 'bg-cyan-950', glow: 'rgba(34, 211, 238, 0.15)', ping: 'bg-cyan-400' },
  { border: 'border-purple-500/30', text: 'text-purple-100', accent: 'text-purple-400', bg: 'bg-purple-950', glow: 'rgba(168, 85, 247, 0.15)', ping: 'bg-purple-400' },
  { border: 'border-emerald-500/30', text: 'text-emerald-100', accent: 'text-emerald-400', bg: 'bg-emerald-950', glow: 'rgba(52, 211, 153, 0.15)', ping: 'bg-emerald-400' },
  { border: 'border-rose-500/30', text: 'text-rose-100', accent: 'text-rose-400', bg: 'bg-rose-950', glow: 'rgba(251, 113, 133, 0.15)', ping: 'bg-rose-400' },
  { border: 'border-amber-500/30', text: 'text-amber-100', accent: 'text-amber-400', bg: 'bg-amber-950', glow: 'rgba(251, 191, 36, 0.15)', ping: 'bg-amber-400' },
];

const AgentCard = ({ agent, onSelect, onEdit }) => {
  const colorIndex = Math.abs(agent.id?.hashCode() || agent.name.hashCode()) % AGENT_THEMES.length;
  const theme = AGENT_THEMES[colorIndex];
  
  return (
    <motion.button
      onClick={() => onSelect(agent)}
      className={`group relative w-full flex flex-col p-3 pt-1 rounded-lg border transition-all h-[100px]
        bg-gray-800 ${theme.border} hover:bg-gray-800/90`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      whileHover={{ boxShadow: `0 0 20px ${theme.glow}` }}
    >
      <CircuitBackground color={theme.glow} />
      <div className="flex items-start justify-between relative">
        <div className="flex items-center gap-2">
          <SimpleAgentIcon theme={theme} />
          {agent.is_default && (
            <span className={`text-[10px] px-1.5 py-0.5 rounded-sm ${theme.bg} ${theme.text} ${theme.border}`}>
              SYSTEM
            </span>
          )}
        </div>
      </div>
      
      <div 
        className="flex-1 flex flex-col justify-center relative"
        title={agent.description || 'No description'}
      >
        <div className={`text-sm font-medium text-center line-clamp-1 ${theme.text}`}>
          {agent.name}
        </div>
        <p className={`text-xs line-clamp-2 text-center w-[90%] mx-auto mt-1 ${theme.text}/70`}>
          {agent.description || 'No description'}
        </p>
      </div>

      {onEdit && (
        <Button
          variant="ghost"
          onClick={(e) => {
            e.stopPropagation();
            onEdit?.(agent);
          }}
          className={`absolute top-1.5 right-2 p-1.5 opacity-0 group-hover:opacity-100 transition-opacity ${theme.bg} rounded-full shadow-sm hover:opacity-80`}
          icon={<Edit className={`h-3.5 w-3.5 ${theme.text}`} />}
        />
      )}
    </motion.button>
  );
};

export default AgentCard;