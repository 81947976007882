import React, { useState, useRef } from 'react';
import { Upload, Link, File, Table } from 'lucide-react';
import Button from './ui/Button';
import Modal from './ui/Modal';

const DataSourceDialog = ({ isOpen, onClose, onSubmit, isSubmitting }) => {
  const [inputType, setInputType] = useState('file');
  const [dataType, setdataType] = useState('unstructured');
  const [urlDataSource, setUrlDataSource] = useState('');
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const handleSubmit = async () => {
    const dataSourceRequest = {
      inputType: inputType,
      dataType: dataType,
    };
    if (inputType === 'file') {
      dataSourceRequest.file = file;
    } else if (inputType === 'url') {
      dataSourceRequest.url = urlDataSource;
    }
    await onSubmit(dataSourceRequest);
    resetForm();
  };

  const resetForm = () => {
    setInputType('file');
    setdataType('unstructured');
    setUrlDataSource('');
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title="Add Data Source"
      color="indigo"
      disabled={isSubmitting}
    >
      <div className="p-6">
        <div className="mb-4">
          <div className="flex space-x-2">
            <Button
              variant={inputType === 'file' && dataType === 'unstructured' ? 'primary' : 'secondary'}
              onClick={() => {
                setInputType('file');
                setdataType('unstructured');
              }}
              icon={<File className="h-4 w-4" />}
            >
              Unstructured
            </Button>
            <Button
              variant={inputType === 'file' && dataType === 'structured' ? 'primary' : 'secondary'}
              onClick={() => {
                setInputType('file');
                setdataType('structured');
              }}
              icon={<Table className="h-4 w-4" />}
              className="hidden"
            >
              Structured
            </Button>
            <Button
              variant={inputType === 'url' ? 'primary' : 'secondary'}
              onClick={() => {
                setInputType('url');
                setdataType('unstructured');
              }}
              icon={<Link className="h-4 w-4" />}
            >
              URL
            </Button>
          </div>
        </div>

        {inputType === 'file' ? (
          <div className="mb-4">
            <div className="flex items-center justify-center w-full">
              <label 
                htmlFor="file-upload" 
                className="flex flex-col items-center justify-center w-full h-24 
                  border-2 border-indigo-500/20 border-dashed rounded-lg cursor-pointer 
                  bg-gray-700/50 hover:bg-gray-700/70 transition-colors duration-150"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <Upload className="w-6 h-6 mb-2 text-indigo-400" />
                  <p className="text-xs text-indigo-300/70">
                    <span className="font-semibold">Click to upload</span> or drag and drop
                  </p>
                  {file && (
                    <p className="text-xs text-indigo-300/70 mt-2">
                      {file.name}
                    </p>
                  )}
                </div>
                <input
                  id="file-upload"
                  type="file"
                  className="hidden"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept={dataType === 'structured' 
                    ? ".csv,.xls,.xlsx" 
                    : ".txt,.pdf,.doc,.docx,.html,.json,.py,.js,.zip,.tar,.gz,.jpg,.webp,.png,.mp3,.mp4"
                  }
                />
              </label>
            </div>
            {dataType === 'structured' && (
              <p className="text-xs text-indigo-300/70 mt-2">
                Supported file types: CSV, XLS, XLSX
              </p>
            )}
          </div>
        ) : (
          <div className="mb-4">
            <input
              type="url"
              value={urlDataSource}
              onChange={(e) => setUrlDataSource(e.target.value)}
              placeholder="https://example.com/data-source"
              className="w-full px-4 py-2 bg-gray-700/50 border border-indigo-500/20 
                rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500/50 
                text-indigo-100 placeholder-indigo-300/30"
            />
          </div>
        )}
      </div>

      <div className="border-t border-indigo-500/20 p-6 flex justify-end space-x-2">
        <Button
          variant="secondary"
          onClick={handleClose}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          isLoading={isSubmitting}
          disabled={isSubmitting || (inputType === 'file' && !file) || (inputType === 'url' && !urlDataSource)}
          icon={!isSubmitting && <Upload className="h-4 w-4" />}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default DataSourceDialog;