import React, { useState, useEffect, useRef } from 'react';
import ProjectDetails from './ProjectDetails';
import ErrorPopup from './ErrorPopup';
import AgentDetails from './AgentDetails';
import CarouselRow from './CarouselRow';
import InputForm from './InputForm';
import ShareProjectDialog from './ShareProjectDialog';
import { User, FolderPlus, UserPlus, Filter, ChevronUp, ChevronDown } from 'lucide-react';
import ConfirmationDialog from './ConfirmationDialog';
import FilterBar from './FilterBar';
import RecentConversations from './RecentConversations';
import ProjectDropdown from './ProjectDropdown';
import Button from './ui/Button';

const ChatSection = ({ 
  inputValue, 
  setInputValue, 
  handleQuickStart, 
  isCreatingNewThread,
  selectedProjectId,
  inputRef,
  setIsSidebarCollapsed,
  isSmallScreen 
}) => (
  <div className={`${isSmallScreen ? 'mt-6' : 'mb-4 mr-3'}`}>
    <div className="border border-gray-600 rounded-xl bg-gray-700 shadow-sm">
      <InputForm
        inputValue={inputValue}
        setInputValue={setInputValue}
        handleSendMessage={handleQuickStart}
        isMessageLoading={isCreatingNewThread}
        placeholder="Type what you want to do..."
        quickStart={true}
        selectedProjectId={selectedProjectId}
        rows={1}
        ref={inputRef}
        setIsSidebarCollapsed={setIsSidebarCollapsed}
        className={isSmallScreen ? 'text-base py-2' : ''}
      />
    </div>
  </div>
);

const AgentsSection = ({
  showAgentsSection,
  showAgentFilters,
  showDefaultAgents,
  setShowDefaultAgents,
  searchAgents,
  setSearchAgents,
  filteredAgents,
  handleEditAgent,
  isSmallScreen
}) => (
  showAgentsSection && (
    <>
      {showAgentFilters && (
        <div className="mb-3">
          <FilterBar
            showShared={showDefaultAgents}
            setShowShared={setShowDefaultAgents}
            searchTerm={searchAgents}
            setSearchTerm={setSearchAgents}
            itemType="agents"
            filterLabel="My"
            isSmallScreen={isSmallScreen}
          />
        </div>
      )}
      <div className="flex justify-center w-full">
        <div className="w-full max-w-[1200px] px-4">
          <CarouselRow
            items={filteredAgents}
            onItemSelect={handleEditAgent}
            selectedId={null}
            onEditItem={handleEditAgent}
            itemType="agent"
          />
        </div>
      </div>
    </>
  )
);

const Dashboard = ({ 
  onSelectProject, 
  projects, 
  threads, 
  isCreatingNewThread,
  selectedProjectId,
  user,
  handleLogOut,
  createProject,
  updateProject,
  deleteProject,
  agents,
  handleQuickStart,
  inputValue,
  setInputValue,
  setShowDashboard,
  createAgent,
  updateAgent,
  deleteAgent,
  addDataSource,
  removeDataSource,
  resetAgent,
  agentData,
  shareProject,
  setIsSidebarCollapsed,
  deleteThread,
  isDeletingThread,
  isSmallScreen,
}) => {
  const [error, setError] = useState(null);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [isCreatingProject, setIsCreatingProject] = useState(false);
  const [editingProject, setEditingProject] = useState(null);
  const [editingAgent, setEditingAgent] = useState(null);
  const [sharingProject, setSharingProject] = useState(null);
  const [showLogout, setShowLogout] = useState(false);
  const inputRef = useRef(null);
  const [threadToDelete, setThreadToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showShared, setShowShared] = useState(true);
  const [searchAgents, setSearchAgents] = useState('');
  const [showDefaultAgents, setShowDefaultAgents] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [showAgentFilters, setShowAgentFilters] = useState(false);
  const [showAgentsSection, setShowAgentsSection] = useState(!isSmallScreen);
  const [showProjectsSection, setShowProjectsSection] = useState(true);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  // Update threads when project is selected
  const handleProjectSelect = async (projectId) => {
    try {
      await onSelectProject(projectId);
    } catch (error) {
      console.error('Error selecting project:', error);
    }
  };

  // Show threads directly from the threads prop
  const recentThreads = isSmallScreen 
    ? threads?.slice(0, 2) || []  // Show only 2 conversations on mobile
    : threads?.slice(0, 3) || []; // Show 3 conversations on desktop

  const handleCreateProject = async (projectData) => {
    setIsCreatingProject(true);
    try {
      const result = await createProject(projectData.name, {
        objective: projectData.objective,
        key_results: projectData.keyResults,
        metrics: projectData.metrics
      });
      if (result?.project) {
        handleProjectSelect(result.project._id);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsCreatingProject(false);
    }
  };

  const handleUpdateProject = async (projectId, updatedData) => {
    try {
      await updateProject(projectId, updatedData);
      setEditingProject(null);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDeleteProject = async (projectId) => {
    try {
      await deleteProject(projectId);
      setEditingProject(null);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleEditAgent = (agent) => {
    setEditingAgent(agent);
  };

  // Handler for selecting an existing conversation
  const handleConversationSelect = async (projectId, threadId) => {
    try {
      // First select the project if it's different
      if (projectId !== selectedProjectId) {
        await onSelectProject(projectId);
      }
      
      await onSelectProject(projectId, threadId);
      setShowDashboard(false);
      setIsSidebarCollapsed(true);
    } catch (error) {
      setError(error.message);
    }
  };

  // Separate handler for share project
  const handleShareProject = (project) => {
    setSharingProject(project);
    setShowShareDialog(true);
  };

  // Rename this to be more specific
  const handleEditProjectClick = (project) => {
    setEditingProject(project);
  };

  // Add click handler for the dashboard container
  const handleDashboardClick = () => {
    setShowLogout(false);
  };

  // Sort projects by creation date (newest first)
  const sortedProjects = [...(projects || [])].sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });

  // Sort agents by creation date (newest first)
  const sortedAgents = [...(agents || [])].sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });

  const handleDeleteThreadClick = (threadId) => {
    setThreadToDelete(threadId);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteThread(threadToDelete);
    } catch (error) {
      setError(error.message);
    } finally {
      setThreadToDelete(null);
    }
  };

  const filteredProjects = sortedProjects
    .filter(project => showShared || !project.is_shared)
    .filter(project => 
      project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (project.objective || '').toLowerCase().includes(searchTerm.toLowerCase())
    );

  const filteredAgents = sortedAgents
    .filter(agent => 
      showDefaultAgents || !agent.is_default
    )
    .filter(agent =>
      agent.name.toLowerCase().includes(searchAgents.toLowerCase()) ||
      (agent.description || '').toLowerCase().includes(searchAgents.toLowerCase())
    );

  // Add this helper function to get selected project name
  const getSelectedProjectName = () => {
    return projects.find(p => p._id === selectedProjectId)?.name || '';
  };

  return (
    <div 
      className={`flex-1 flex flex-col overflow-y-auto bg-gray-900 text-white ${isSmallScreen ? '' : 'rounded-2xl'}`}
      onClick={handleDashboardClick}
    >
      <div className="absolute top-4 right-4">
        <div className="relative">
          <button
            onClick={(e) => {
              e.stopPropagation();
              setShowLogout(!showLogout);
            }}
            className="flex items-center focus:outline-none"
          >
            {user.picture ? (
              <img
                src={user.picture}
                alt={user.name}
                className="w-8 h-8 rounded-full"
              />
            ) : (
              <User size={24} className="text-gray-500" />
            )}
          </button>
          {showLogout && (
            <div 
              className="absolute right-0 mt-2 py-2 w-48 bg-white border-gray-200 border rounded shadow-xl z-50"
              onClick={(e) => e.stopPropagation()}
            >
              <button
                className="flex items-center px-4 py-2 w-full text-left text-sm text-gray-700 hover:bg-gray-100"
                onClick={handleLogOut}
              >
                <User size={16} className="mr-2" />
                Logout
              </button>
            </div>
          )}
        </div>
      </div>

      <div className={`${isSmallScreen ? 'px-3 pt-8 bg-gray-700' : 'px-6'} min-h-full pt-4 relative pb-16`}>
        <div className={`${isSmallScreen ? 'w-full' : 'max-w-4xl w-full mx-auto'}`}>
          {isSmallScreen ? (
            // Mobile Layout
            <>
              {/* Projects Section - Added more top padding */}
              <div className="mt-24">
                <div className="flex items-center justify-center gap-3">
                  <ProjectDropdown 
                    projects={filteredProjects}
                    selectedProjectId={selectedProjectId}
                    onSelect={handleProjectSelect}
                    onEdit={handleEditProjectClick}
                  />
                  <Button
                    variant="icon"
                    onClick={() => setEditingProject({})}
                    icon={<FolderPlus className="h-3.5 w-3.5" />}
                    title="Create New Project"
                  />
                </div>
              </div>

              {/* Chat Input */}
              <ChatSection 
                inputValue={inputValue}
                setInputValue={setInputValue}
                handleQuickStart={handleQuickStart}
                isCreatingNewThread={isCreatingNewThread}
                selectedProjectId={selectedProjectId}
                inputRef={inputRef}
                setIsSidebarCollapsed={setIsSidebarCollapsed}
                isSmallScreen={isSmallScreen}
              />

              {/* Recent Conversations - Removed mt-4 to eliminate gap */}
              {selectedProjectId && (
                <div className="mb-4 mt-4 flex justify-center">
                  <div className="w-[90%] -ml-4 ">
                    <RecentConversations
                      conversations={recentThreads.map(thread => ({
                        ...thread,
                        projectId: selectedProjectId
                      }))}
                      onSelect={handleConversationSelect}
                      onDelete={handleDeleteThreadClick}
                      isDeletingThread={isDeletingThread}
                      isSmallScreen={isSmallScreen}
                    />
                  </div>
                </div>
              )}

              {/* Agents Section */}
              {selectedProjectId && (
                <div className="mt-8">
                  <div className="flex items-center justify-center gap-2 mb-3">
                    <Button
                      variant="icon"
                      onClick={() => setEditingAgent({})}
                      icon={<UserPlus className="h-3.5 w-3.5" />}
                      title="Create New Agent"
                    />
                    {showAgentsSection && (
                      <Button
                        variant="icon"
                        onClick={() => setShowAgentFilters(!showAgentFilters)}
                        icon={<Filter className="h-3.5 w-3.5" />}
                        title="Filter Agents"
                      />
                    )}
                    <Button
                      variant="icon"
                      onClick={() => {
                        setShowAgentsSection(!showAgentsSection);
                        if (!showAgentsSection) {
                          setShowAgentFilters(false);
                        }
                      }}
                      icon={showAgentsSection ? <ChevronUp className="h-3.5 w-3.5" /> : <ChevronDown className="h-3.5 w-3.5" />}
                      title={showAgentsSection ? "Hide Agents" : "Show Agents"}
                    />
                  </div>
                  
                  <AgentsSection
                    showAgentsSection={showAgentsSection}
                    showAgentFilters={showAgentFilters}
                    showDefaultAgents={showDefaultAgents}
                    setShowDefaultAgents={setShowDefaultAgents}
                    searchAgents={searchAgents}
                    setSearchAgents={setSearchAgents}
                    filteredAgents={filteredAgents}
                    handleEditAgent={handleEditAgent}
                    isSmallScreen={isSmallScreen}
                  />
                </div>
              )}
            </>
          ) : (
            // Desktop Layout
            <div className="bg-gray-700 rounded-2xl p-6 max-w-[1400px] mx-auto">
              {/* Projects Section */}
              <div className="mt-3 flex items-center justify-center gap-2">
                <Button
                  variant="icon"
                  onClick={() => setEditingProject({})}
                  icon={<FolderPlus className="h-3.5 w-3.5" />}
                  title="Create New Project"
                />
                {showProjectsSection && (
                  <Button
                    variant="icon"
                    onClick={() => setShowFilters(!showFilters)}
                    icon={<Filter className="h-3.5 w-3.5" />}
                    title="Filter Projects"
                  />
                )}
                <Button
                  variant="icon"
                  onClick={() => {
                    setShowProjectsSection(!showProjectsSection);
                    if (!showProjectsSection) {
                      setShowFilters(false);
                    }
                  }}
                  icon={showProjectsSection ? <ChevronUp className="h-3.5 w-3.5" /> : <ChevronDown className="h-3.5 w-3.5" />}
                  title={showProjectsSection ? "Hide Projects" : "Show Projects"}
                />
              </div>
              {!showProjectsSection && selectedProjectId && (
                <div className="mt-2 mb-6 text-center">
                  <p className="text-sm font-semibold text-gray-200">{getSelectedProjectName()}</p>
                </div>
              )}
              {showProjectsSection && (
                <>
                  {showFilters && (
                    <div className="mt-3">
                      <FilterBar
                        showShared={showShared}
                        setShowShared={setShowShared}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        itemType="projects"
                        isSmallScreen={isSmallScreen}
                      />
                    </div>
                  )}
                  <div className="flex justify-center w-full mb-8">
                    <div className="w-full max-w-[800px] px-4">
                      <CarouselRow
                        items={filteredProjects}
                        onItemSelect={handleProjectSelect}
                        selectedId={selectedProjectId}
                        onEditItem={handleEditProjectClick}
                        onShareItem={handleShareProject}
                        itemType="project"
                        itemDataAttribute="data-project-id"
                      />
                    </div>
                  </div>
                </>
              )}

              {/* Chat Input */}
              <ChatSection 
                inputValue={inputValue}
                setInputValue={setInputValue}
                handleQuickStart={handleQuickStart}
                isCreatingNewThread={isCreatingNewThread}
                selectedProjectId={selectedProjectId}
                inputRef={inputRef}
                setIsSidebarCollapsed={setIsSidebarCollapsed}
                isSmallScreen={isSmallScreen}
              />

              {selectedProjectId && (
                <div className="mt-4 mb-4 px-8 -ml-8">
                  <RecentConversations
                    conversations={recentThreads.map(thread => ({
                      ...thread,
                      projectId: selectedProjectId
                    }))}
                    onSelect={handleConversationSelect}
                    onDelete={handleDeleteThreadClick}
                    isDeletingThread={isDeletingThread}
                    isSmallScreen={isSmallScreen}
                  />
                </div>
              )}

              {/* Agents Section */}
              {selectedProjectId && (
                <div className="mt-8">
                  <div className="flex items-center justify-center gap-2 mb-3">
                    <Button
                      variant="icon"
                      onClick={() => setEditingAgent({})}
                      icon={<UserPlus className="h-3.5 w-3.5" />}
                      title="Create New Agent"
                    />
                    {showAgentsSection && (
                      <Button
                        variant="icon"
                        onClick={() => setShowAgentFilters(!showAgentFilters)}
                        icon={<Filter className="h-3.5 w-3.5" />}
                        title="Filter Agents"
                      />
                    )}
                    <Button
                      variant="icon"
                      onClick={() => {
                        setShowAgentsSection(!showAgentsSection);
                        if (!showAgentsSection) {
                          setShowAgentFilters(false);
                        }
                      }}
                      icon={showAgentsSection ? <ChevronUp className="h-3.5 w-3.5" /> : <ChevronDown className="h-3.5 w-3.5" />}
                      title={showAgentsSection ? "Hide Agents" : "Show Agents"}
                    />
                  </div>
                  
                  <AgentsSection
                    showAgentsSection={showAgentsSection}
                    showAgentFilters={showAgentFilters}
                    showDefaultAgents={showDefaultAgents}
                    setShowDefaultAgents={setShowDefaultAgents}
                    searchAgents={searchAgents}
                    setSearchAgents={setSearchAgents}
                    filteredAgents={filteredAgents}
                    handleEditAgent={handleEditAgent}
                    isSmallScreen={isSmallScreen}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        {/* AI Responses Disclaimer - Now positioned absolutely at the bottom */}
        <div className="absolute bottom-4 left-0 right-0 text-center">
          <p className="text-[11px] sm:text-[12px] text-gray-400 max-w-2xl mx-auto px-4">
            AI responses may be inaccurate. Please verify important information.
          </p>
        </div>
      </div>

      {/* Project Details Dialog */}
      {editingProject !== null && (
        <ProjectDetails
          isOpen={true}
          onClose={() => {
            setEditingProject(null);
          }}
          project={editingProject}
          updateProject={handleUpdateProject}
          isNewProject={!editingProject._id}
          createProject={handleCreateProject}
          deleteProject={handleDeleteProject}
          isLoading={isCreatingProject}
        />
      )}

      {editingAgent && (
        <AgentDetails
          isOpen={true}
          onClose={() => setEditingAgent(null)}
          agent={editingAgent.id ? editingAgent : undefined}
          createAgent={createAgent}
          updateAgent={updateAgent}
          isNewAgent={!editingAgent.id}
          isLoading={false}
          deleteAgent={deleteAgent}
          addDataSource={addDataSource}
          removeDataSource={removeDataSource}
          resetAgent={resetAgent}
          agentData={agentData}
        />
      )}

      {error && (
        <ErrorPopup 
          message={error} 
          onClose={() => setError(null)} 
        />
      )}

      {showShareDialog && sharingProject && (
        <ShareProjectDialog
          onClose={() => {
            setShowShareDialog(false);
            setSharingProject(null);
          }}
          onShare={async (email) => {
            try {
              await shareProject(sharingProject._id, email);
              setShowShareDialog(false);
              setSharingProject(null);
            } catch (error) {
              setError(error.message);
            }
          }}
        />
      )}

      {threadToDelete && (
        <ConfirmationDialog
          title="Delete Conversation"
          message="Are you sure you want to delete this conversation? This action cannot be undone."
          onConfirm={handleConfirmDelete}
          onCancel={() => setThreadToDelete(null)}
          confirmText="Delete"
          isLoading={isDeletingThread}
        />
      )}
    </div>
  );
};

export default Dashboard;