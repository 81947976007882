import React from 'react';
import { Search, X, Users, User } from 'lucide-react';

const FilterBar = ({ 
  showShared, 
  setShowShared,
  searchTerm,
  setSearchTerm,
  itemType = "projects",
  filterLabel = "My",
  isSmallScreen
}) => {
  return (
    <div className="flex items-center gap-2 bg-gray-700 p-2 rounded-lg w-full">
      <div className="flex items-center flex-shrink-0 gap-1.5">
        <button
          onClick={() => setShowShared(true)}
          className={`px-2 py-0.5 text-sm rounded-md transition-colors flex items-center gap-1 ${
            showShared 
              ? 'bg-blue-600/20 text-gray-200 border border-blue-600/20' 
              : 'bg-gray-700 text-gray-400 hover:bg-gray-600 border border-gray-600'
          }`}
        >
          {isSmallScreen ? (
            <Users className="h-4 w-4" />
          ) : (
            'All'
          )}
        </button>
        <button
          onClick={() => setShowShared(false)}
          className={`px-2 py-0.5 text-sm rounded-md transition-colors flex items-center gap-1 ${
            !showShared 
              ? 'bg-blue-500/20 text-gray-200 border border-blue-500/20' 
              : 'bg-gray-700 text-gray-400 hover:bg-gray-600 border border-gray-600'
          }`}
        >
          {isSmallScreen ? (
            <User className="h-4 w-4" />
          ) : (
            `${filterLabel} ${itemType}`
          )}
        </button>
      </div>

      <div className="relative flex-1">
        <div className="relative w-full">
          <div className="absolute inset-y-0 left-2 flex items-center pointer-events-none">
            <Search className="h-3 w-3 text-gray-400" />
          </div>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={`w-full pl-7 pr-7 py-0.5 text-sm bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-gray-200 ${
              isSmallScreen ? 'text-center' : ''
            }`}
            placeholder={`Search ${itemType}...`}
          />
          {searchTerm && (
            <button
              onClick={() => setSearchTerm('')}
              className="absolute inset-y-0 right-2 flex items-center"
            >
              <X className="h-3 w-3 text-gray-400 hover:text-gray-200" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterBar;