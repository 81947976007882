import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const Footer = ({ className }) => (
    <motion.footer 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.3 }}
        className={`relative z-20 w-full py-2 sm:py-4 bg-black/40 backdrop-blur-lg border-t border-white/5 ${className}`}
    >
        <div className="container mx-auto px-3 sm:px-4">
            <div className="flex flex-col items-center space-y-2 sm:space-y-0 sm:flex-row sm:justify-center sm:space-x-4">
                {/* Copyright and All Rights Reserved */}
                <div className="flex items-center space-x-1 sm:space-x-4 text-[10px] sm:text-sm text-gray-400/80">
                    <motion.span
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 1.4 }}
                    >
                        &copy; {new Date().getFullYear()} PurposeMind AI
                    </motion.span>
                    <span className="text-gray-600/50">•</span>
                    <motion.span
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 1.5 }}
                    >
                        All rights reserved
                    </motion.span>
                </div>
                
                {/* Links */}
                <div className="flex items-center space-x-2 sm:space-x-4 text-[10px] sm:text-sm">
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 1.6 }}
                    >
                        <Link 
                            to="/terms"
                            className="text-gray-400/80 hover:text-blue-400 transition-colors duration-300"
                        >
                            Terms
                        </Link>
                    </motion.div>
                    
                    <span className="text-gray-600/50">•</span>
                    
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 1.7 }}
                    >
                        <Link 
                            to="/privacy"
                            className="text-gray-400/80 hover:text-blue-400 transition-colors duration-300"
                        >
                            Privacy
                        </Link>
                    </motion.div>
                </div>
            </div>
        </div>
        
        {/* Gradient overlay */}
        <div className="absolute inset-0 -z-10 bg-gradient-to-t from-black/20 to-transparent pointer-events-none" />
    </motion.footer>
);

export default Footer;
