// Regular expression to match URLs
const urlRegex = /(https?:\/\/[^\s]+)/g;

export const formatNameWithLink = (name) => {
  if (!name) return 'Artifact Details';

  if (urlRegex.test(name)) {
    const url = name.match(urlRegex)[0];
    const displayName = name.replace(urlRegex, 'Link').trim();
    return { displayName, url };
  }

  return { displayName: name, url: null };
};
