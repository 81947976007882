import { StatusCodes, getReasonPhrase } from 'http-status-codes';

export const handleHttpError = (error, action = '') => {
  const message = parseError(error);
  const logMessage = action ? `${action}: ${message}` : message;

  console.error('Error ' + logMessage);  
  throw new Error(logMessage);
};

const parseError = (error) => {
  if (error.response) {
    const { status, data } = error.response;
    return data.detail || data.message || getUserMessage(status);
  }
  if (error.request) {
    return 'Network error. Please check your connection and try again.';
  }
  return error.message || 'An unexpected error occurred. Please try again.';
};

const getUserMessage = (status) => {
  const messages = {
    [StatusCodes.BAD_REQUEST]: 'Invalid request. Please check your input.',
    [StatusCodes.UNAUTHORIZED]: 'Authentication required. Please log in.',
    [StatusCodes.FORBIDDEN]: 'Access denied.',
    [StatusCodes.NOT_FOUND]: 'Resource not found.',
    [StatusCodes.CONFLICT]: 'Request conflicts with the current state.',
    [StatusCodes.UNPROCESSABLE_ENTITY]: 'Unprocessable entity. Please file a bug.',
    [StatusCodes.INTERNAL_SERVER_ERROR]: 'Server error. Please try again later.'
  };
  return messages[status] || `Unexpected error: ${getReasonPhrase(status)}`;
};