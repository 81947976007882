import React, { useRef, useEffect, useState } from 'react';
import { MessageSquare, Trash2, Loader, ChevronLeft, ChevronRight } from 'lucide-react';
import Button from './ui/Button';

const RecentConversations = ({
  conversations,
  onSelect,
  onDelete,
  isDeletingThread,
  isSmallScreen
}) => {
  const scrollContainerRef = useRef(null);
  const [showLeftChevron, setShowLeftChevron] = useState(false);
  const [showRightChevron, setShowRightChevron] = useState(false);

  const checkForOverflow = () => {
    const container = scrollContainerRef.current;
    if (container) {
      setShowLeftChevron(container.scrollLeft > 0);
      setShowRightChevron(
        container.scrollLeft < container.scrollWidth - container.clientWidth
      );
    }
  };

  useEffect(() => {
    checkForOverflow();
    window.addEventListener('resize', checkForOverflow);
    return () => window.removeEventListener('resize', checkForOverflow);
  }, [conversations]);

  const handleScroll = () => {
    checkForOverflow();
  };

  const scroll = (direction) => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  if (!conversations || conversations.length === 0) {
    return null;
  }

  const isSingleConversation = conversations.length === 1;

  return (
    <div className="relative flex justify-center w-full">
      {showLeftChevron && (
        <Button
          variant="icon"
          onClick={() => scroll('left')}
          icon={<ChevronLeft className="h-4 w-4 text-gray-200" />}
          className="absolute left-0 top-1/2 -translate-y-1/2 z-10"
        />
      )}

      <div 
        ref={scrollContainerRef}
        onScroll={handleScroll}
        className={`flex ${isSingleConversation ? 'justify-center' : 'gap-2'} overflow-x-auto scrollbar-hide max-w-full px-6`}
      >
        {conversations.map((conversation) => (
          <button
            key={conversation.id}
            onClick={() => onSelect(conversation.projectId, conversation.id)}
            className={`relative flex items-center p-1.5 bg-gray-800 rounded-md border border-gray-600 hover:bg-gray-700 
              transition-all group text-left flex-shrink-0 ${
                isSmallScreen ? 'max-w-[150px]' : 'max-w-[250px]'
              }`}
            title={conversation.title}
          >
            <MessageSquare className="h-3.5 w-3.5 text-gray-200 flex-shrink-0 mr-1.5" />
            <span className="text-xs text-gray-200 line-clamp-1 pr-1">
              {conversation.title || 'Untitled Conversation'}
            </span>
            
            {onDelete && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(conversation.id);
                }}
                className="absolute right-1 p-1 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-600 rounded-full shadow-sm hover:bg-gray-500"
                disabled={isDeletingThread}
                title="Delete conversation"
              >
                {isDeletingThread ? (
                  <Loader className="h-2.5 w-2.5 text-gray-400 animate-spin" />
                ) : (
                  <Trash2 className="h-2.5 w-2.5 text-gray-200 hover:text-red-500" />
                )}
              </button>
            )}
          </button>
        ))}
      </div>

      {showRightChevron && (
        <button
          onClick={() => scroll('right')}
          className="absolute right-0 top-1/2 -translate-y-1/2 z-10 bg-gray-800 rounded-full p-1 shadow-sm hover:bg-gray-700 border border-gray-600"
        >
          <ChevronRight className="h-4 w-4 text-gray-200" />
        </button>
      )}
    </div>
  );
};

export default RecentConversations;