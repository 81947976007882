import React from 'react';
import { motion } from 'framer-motion';
import { CircuitBackground } from '../../utils/styledComponents';
import { X } from 'lucide-react';
import Button from './Button';

const Modal = ({ 
  isOpen, 
  onClose, 
  children, 
  title,
  maxWidth = 'md', // sm, md, lg, xl, 2xl, etc.
  showClose = true,
  color = 'blue', // blue, indigo, etc.
  disabled = false,
}) => {
  if (!isOpen) return null;

  const maxWidthClasses = {
    sm: 'max-w-sm',
    md: 'max-w-md',
    lg: 'max-w-lg',
    xl: 'max-w-xl',
    '2xl': 'max-w-2xl',
    '3xl': 'max-w-3xl',
    '4xl': 'max-w-4xl',
    '5xl': 'max-w-5xl',
    full: 'max-w-full'
  };

  const colorClasses = {
    blue: {
      border: 'border-blue-500/20',
      text: 'text-blue-100',
      background: 'rgba(59, 130, 246, 0.3)'
    },
    indigo: {
      border: 'border-indigo-500/20',
      text: 'text-indigo-100',
      background: 'rgba(99, 102, 241, 0.3)'
    }
  };

  const theme = colorClasses[color];

  return (
    <motion.div 
      className="fixed inset-0 bg-black/50 flex justify-center items-center z-50 backdrop-blur-sm p-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div 
        className={`bg-gray-800/90 rounded-lg shadow-xl w-full ${maxWidthClasses[maxWidth]} border ${theme.border} relative`}
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
      >
        <CircuitBackground color={theme.background} />
        
        {(title || showClose) && (
          <div className="flex justify-between items-center p-6 border-b border-gray-700 relative">
            {title && (
              <motion.h2 
                className={`text-xl font-semibold ${theme.text}`}
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
              >
                {title}
              </motion.h2>
            )}
            {showClose && (
              <Button
                variant="ghost"
                onClick={onClose}
                disabled={disabled}
                icon={<X size={20} />}
              />
            )}
          </div>
        )}

        {children}
      </motion.div>
    </motion.div>
  );
};

export default Modal; 