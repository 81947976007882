import React, { useState } from 'react';
import { X, Copy, Check, ExternalLink, ChevronDown, ChevronRight, Chrome, LayoutGrid, Moon, Sun } from 'lucide-react';
import SharedMarkdown from '../utils/SharedMarkdown';
import { getArtifactIcon } from '../utils/iconUtils';
import { formatNameWithLink } from '../utils/urlUtils';
import copy from 'clipboard-copy';
import { truncateString, getTooltipText } from '../utils/stringUtils';
import ErrorPopup from '../components/ErrorPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import Button from './ui/Button';

const getFileType = (url) => {
  const extension = url.split('.').pop().toLowerCase();
  switch (extension) {
    case 'pptx':
    case 'ppt':
      return 'powerpoint';
    case 'docx':
    case 'doc':
      return 'word';
    case 'pdf':
      return 'pdf';
    default:
      return 'unknown';
  }
};

const GoogleViewer = ({ url }) => {
  const encodedUrl = encodeURIComponent(url);
  return (
    <div className="relative w-full h-full">
      <iframe
        src={`https://docs.google.com/viewer?url=${encodedUrl}&embedded=true`}
        width="100%"
        height="100%"
        title="Google Document Viewer"
      />
    </div>
  );
};

const MicrosoftViewer = ({ url }) => {
  const encodedUrl = encodeURIComponent(url);
  return (
    <div className="relative w-full h-full">
      <iframe
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodedUrl}&wdFitPage=true`}
        width="100%"
        height="100%"
        title="Microsoft Document Viewer"
      />
    </div>
  );
};

const FileViewer = ({ url, fileType, useGoogleViewer, setUseGoogleViewer, darkMode }) => {
  const ViewerComponent = useGoogleViewer ? GoogleViewer : MicrosoftViewer;

  switch (fileType) {
    case 'powerpoint':
      return (
        <div className={`relative w-full h-full ${darkMode ? 'invert-[.85] hue-rotate-180' : ''}`}>
          <ViewerComponent url={url} />
        </div>
      );
    case 'word':
      return (
        <div className={`relative w-full h-full ${darkMode ? 'invert-[.85] hue-rotate-180' : ''}`}>
          <ViewerComponent url={url} />
        </div>
      );
    case 'pdf':
      return (
        <div className={`relative w-full h-full ${darkMode ? 'invert-[.85] hue-rotate-180' : ''}`}>
          <ViewerComponent url={url} />
        </div>
      );
    default:
      return <p>Unsupported file type</p>;
  }
};

const JsonView = ({ data }) => {
  const [collapsed, setCollapsed] = useState({});

  const toggleCollapse = (path) => {
    setCollapsed(prev => ({ ...prev, [path]: !prev[path] }));
  };

  const renderValue = (value, path = '', key = null) => {
    const renderKeyValue = (k, v) => (
      <div className={path ? "ml-4" : ""}>
        <span className="text-red-400">{k}: </span>
        {renderValueContent(v, `${path}.${k}`, k)}
      </div>
    );

    const renderValueContent = (v, p, k) => {
      if (v === null) return <span className="text-gray-400">null</span>;
      if (typeof v === 'boolean') return <span className="text-purple-400">{v.toString()}</span>;
      if (typeof v === 'number') return <span className="text-blue-400">{v}</span>;
      if (typeof v === 'string') return <span className="text-green-400">"{v}"</span>;
      if (Array.isArray(v) || typeof v === 'object') {
        const isArray = Array.isArray(v);
        const isCollapsed = collapsed[p];
        const length = isArray ? v.length : Object.keys(v).length;
        return (
          <span>
            <span 
              className="cursor-pointer inline-flex items-center text-gray-200"
              onClick={() => toggleCollapse(p)}
            >
              {isCollapsed ? <ChevronRight size={14} /> : <ChevronDown size={14} />}
            </span>
            {!isCollapsed && (
              <div className="ml-4">
                {isArray
                  ? v.map((item, index) => renderKeyValue(index, item))
                  : Object.entries(v).map(([objKey, val]) => renderKeyValue(objKey, val))
                }
              </div>
            )}
          </span>
        );
      }
      return String(v);
    };

    return key !== null ? renderKeyValue(key, value) : renderValueContent(value, path, key);
  };

  return <div className="font-mono text-sm text-gray-200">{renderValue(data)}</div>;
};

const Workbench = ({ artifact, isSmallScreen, onClose }) => {
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState(null);
  const [useGoogleViewer, setUseGoogleViewer] = useState(true);
  const [darkMode, setDarkMode] = useState(true);

  if (!artifact) return null;

  const { content, metadata } = artifact;

  const icon = getArtifactIcon(metadata?.artifact_type, metadata?.icon);

  const handleCopy = async () => {
    try {
      await copy(content);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      setError('Failed to copy content to clipboard');
    }
  };

  let parsedContent;
  let isJson = false;
  try {
    parsedContent = JSON.parse(content);
    isJson = true;
  } catch (e) {
    parsedContent = null;
  }

  const isFileContent = parsedContent && parsedContent.download_url && parsedContent.file_type;

  const renderContent = () => {
    try {
      if (isFileContent) {
        return (
          <div className="h-full">
            <FileViewer 
              url={parsedContent.download_url} 
              fileType={parsedContent.file_type}
              useGoogleViewer={useGoogleViewer}
              setUseGoogleViewer={setUseGoogleViewer}
              darkMode={darkMode}
            />
          </div>
        );
      } else if (isJson) {
        return (
          <div className="bg-gray-50 rounded-lg overflow-x-auto">
            <JsonView data={parsedContent} />
          </div>
        );
      } else {
        return <SharedMarkdown>{content}</SharedMarkdown>;
      }
    } catch (error) {
      setError('Failed to render content');
      return <div>Error displaying content</div>;
    }
  };

  const renderTitle = () => {
    const name = metadata?.name;
    const { displayName, url } = formatNameWithLink(name);
    
    // Truncate the display name and get the full name for tooltip
    let { truncated: truncatedDisplayName, full: fullDisplayName } = truncateString(displayName, isSmallScreen ? 40 : 50);

    // For small screen, we want to omit the name of the artifact, just show the icon and arguments
    if (isSmallScreen && truncatedDisplayName) {
      const parts = truncatedDisplayName.split(':');
      truncatedDisplayName = parts[0];
    }

    const tooltipText = getTooltipText(fullDisplayName);

    return (
      <span className="flex items-center" title={tooltipText}>
        {truncatedDisplayName}
        {url && (
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className={`ml-2 text-blue-600 hover:text-blue-800 flex items-center ${isSmallScreen ? 'text-xs' : 'text-sm'}`}
          >
            <ExternalLink size={12} className="ml-1" />
          </a>
        )}
      </span>
    );
  };

  const isDocument = parsedContent?.file_type && ['word', 'powerpoint', 'pdf'].includes(parsedContent.file_type);

  return (
    <div className="detail-panel flex flex-col h-full font-sans text-sm relative z-40 bg-gray-700 rounded-lg">
      <div className="bg-gray-900/90 p-1.5 flex justify-between items-center rounded-t-lg">
        <h2 className="text-base font-medium text-gray-200 flex items-center font-sans">
          <span className="mr-1.5">{icon}</span>
          {renderTitle()}
        </h2>
        <div className="flex items-center">
          <button 
            onClick={onClose} 
            className="text-gray-400 hover:text-gray-200 transition-colors"
          >
            <X size={18} />
          </button>
        </div>
      </div>
      
      {/* Content area */}
      <div className="flex-1 min-h-0 overflow-hidden flex flex-col">
        <div className="flex-1 overflow-y-auto p-1.5">
          <div className={`bg-gray-700 h-full ${!isFileContent && 'p-1.5'}`}>
            {renderContent()}
          </div>
        </div>
      </div>

      {/* Updated Footer */}
      <div className="bg-gray-900/90 p-2 rounded-b-lg flex justify-between items-center">
        <div className="flex gap-2">
          {/* Document viewer toggle buttons */}
          {isFileContent && isDocument && (
            <>
              <Button
                variant="icon"
                onClick={() => setUseGoogleViewer(true)}
                className={useGoogleViewer ? 'bg-blue-500/20 text-blue-400' : ''}
                icon={<FontAwesomeIcon icon={faGoogle} />}
                title="Switch to Google Docs Viewer"
              />
              <button
                onClick={() => setUseGoogleViewer(false)}
                className={`p-1.5 rounded transition-colors w-7 h-7 flex items-center justify-center ${
                  !useGoogleViewer 
                    ? 'bg-blue-500/20 text-blue-400' 
                    : 'text-gray-400 hover:text-gray-200'
                }`}
                title="Switch to Microsoft Office Viewer"
              >
                <FontAwesomeIcon icon={faMicrosoft} />
              </button>
              <button
                onClick={() => setDarkMode(!darkMode)}
                className={`p-1.5 rounded transition-colors w-7 h-7 flex items-center justify-center ${
                  darkMode 
                    ? 'bg-blue-500/20 text-blue-400' 
                    : 'text-gray-400 hover:text-gray-200'
                }`}
                title={darkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
              >
                {darkMode ? <Sun size={16} /> : <Moon size={16} />}
              </button>
            </>
          )}
        </div>
        
        {/* Copy button */}
        <button
          onClick={handleCopy}
          className="text-gray-400 hover:text-gray-200 transition-colors"
          title="Copy to clipboard"
        >
          {copied ? <Check size={16} className="text-green-500" /> : <Copy size={16} />}
        </button>
      </div>
      {error && <ErrorPopup message={error} onClose={() => setError(null)} />}
    </div>
  );
};

export default Workbench;
