import { useState, useCallback } from 'react';
import useApi from './useApi';
import { handleHttpError } from '../utils/errorHandling';

const useDataSources = () => {
  const { api } = useApi();
  const [dataSources, setDataSources] = useState({});

  const fetchDataSources = useCallback(async (projectId, entityId, entity_type) => {
    if (!entityId || !projectId) return;
    try {
      const endpoint = entity_type === 'agent' ? '/agents' : '/conversations';
      const response = await api.get(`${endpoint}/${projectId}/${entityId}/data-sources`);
      if (response.data && response.data.data_sources) {
        setDataSources(prevState => ({
          ...prevState,
          [entityId]: response.data.data_sources
        }));
        return response.data
      }
      return [];
    } catch (error) {
      console.error('Error fetching data sources:', error);
      throw error;
    }
  }, [api]);

  const addDataSource = useCallback(async (projectId, entityId, entity_type, dataSourceRequest) => {
    if (!projectId || !entityId) {
      handleHttpError(new Error('Cannot add data source: No project or entity selected'), 'adding data source');
    }
    
    try {
      const formData = new FormData();
      
      formData.append('input_type', dataSourceRequest.inputType);
      formData.append('data_type', dataSourceRequest.dataType);
      
      if (dataSourceRequest.inputType === 'file' && dataSourceRequest.file instanceof File) {
        formData.append('file', dataSourceRequest.file, dataSourceRequest.file.name);
      } else if (dataSourceRequest.inputType === 'url' && dataSourceRequest.url) {
        formData.append('url', dataSourceRequest.url);
      }
      
      if (dataSourceRequest.name) {
        formData.append('name', dataSourceRequest.name);
      }

      const endpoint = entity_type === 'agent' ? '/agents' : '/conversations';
      const response = await api.post(`${endpoint}/${projectId}/${entityId}/data-sources`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      
      if (response.data && response.data.result) {
        return response.data.result;
      }
      return null;
    } catch (error) {
      handleHttpError(error, 'adding data source');
    }
  }, [api]);

  const removeDataSource = useCallback(async (projectId, entityId, entity_type, dataSourceId) => {
    if (!entityId || !projectId) {
      handleHttpError(new Error('Cannot remove data source: No project or entity selected'), 'removing data source');
    }
    try {
      const endpoint = entity_type === 'agent' ? '/agents' : '/conversations';
      await api.delete(`${endpoint}/${projectId}/${entityId}/data-sources/${dataSourceId}`);
      setDataSources(prevState => ({
        ...prevState,
        [entityId]: (prevState[entityId] || []).filter(source => source.id !== dataSourceId)
      }));
    }
    catch (error) {
      handleHttpError(error, 'removing data source');
    }
  }, [api]);

  return { dataSources, fetchDataSources, addDataSource, removeDataSource };
};

export default useDataSources;
