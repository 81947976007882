import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { Canvas } from '@react-three/fiber';
import { Float, Stars, Points, PointMaterial } from '@react-three/drei';
import gsap from 'gsap';

const CircuitPattern = () => (
  <div className="absolute inset-0 opacity-10">
    <div className="absolute top-0 left-0 w-full h-full">
      <svg width="100%" height="100%" className="circuit-pattern">
        <pattern id="circuit" x="0" y="0" width="50" height="50" patternUnits="userSpaceOnUse">
          <path 
            d="M10 10h30v30h-30z M15 25h20 M25 15v20" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="0.5" 
            className="text-blue-500/20"
          />
          <circle cx="25" cy="25" r="2" className="fill-blue-400/30" />
        </pattern>
        <rect x="0" y="0" width="100%" height="100%" fill="url(#circuit)" />
      </svg>
    </div>
  </div>
);

const HomePage = ({ handleSignIn }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to('.gradient-orb', {
        y: -20,
        duration: 4,
        repeat: -1,
        yoyo: true,
        ease: 'power1.inOut',
      });
    }, containerRef);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={containerRef} className="relative w-full min-h-screen bg-black overflow-x-hidden">
      {/* Background elements */}
      <div className="fixed inset-0 z-0">
        <Canvas>
          <Stars radius={100} depth={50} count={7000} factor={4} fade speed={0.5} saturation={0.8} />
          <Float
            speed={1.5}
            rotationIntensity={0.5}
            floatIntensity={1.5}
          >
            <ambientLight intensity={0.5} />
            <pointLight position={[10, 10, 10]} />
          </Float>
          <Points count={100}>
            <PointMaterial transparent color="#88ccff" size={0.05} sizeAttenuation={true} depthWrite={false} />
          </Points>
        </Canvas>
      </div>

      {/* Circuit Pattern */}
      <div className="fixed inset-0 z-0">
        <CircuitPattern />
      </div>

      {/* Content wrapper */}
      <div className="relative z-10 w-full">
        <main className="flex flex-col items-center justify-start w-full min-h-screen py-8 md:py-12">
          {/* Hero Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center w-full max-w-5xl mx-auto mb-8 sm:mb-12 px-4 sm:px-6 pt-8 sm:pt-12"
          >
            {/* Hero Title - Further improved mobile text sizes */}
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
              className="relative text-3xl sm:text-4xl md:text-7xl font-bold mb-4 sm:mb-6 leading-tight"
            >
              <span className="block text-xl sm:text-3xl md:text-5xl mb-2 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
                Unleashing
              </span>
              <span className="text-2xl sm:text-4xl md:text-8xl font-black bg-clip-text text-transparent bg-gradient-to-r from-white via-blue-100 to-white">
                Human-AI Synergy
              </span>
            </motion.h1>

            {/* Description - Improved readability on mobile */}
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.7 }}
              className="max-w-2xl mx-auto text-base sm:text-lg md:text-xl text-gray-300/90 mb-8 sm:mb-10 leading-relaxed px-4 sm:px-6"
            >
              Harness the power of multiple AI agents working alongside human expertise 
              to drive meaningful outcomes that matter.
            </motion.p>

            {/* CTA Button - Improved mobile responsiveness */}
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.9 }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="mb-8 sm:mb-12 md:mb-16 px-4 sm:px-0"
            >
              <button
                onClick={handleSignIn}
                className="group relative px-6 py-3 bg-white text-black rounded-full 
                  font-bold text-base overflow-hidden transition-all duration-300 
                  shadow-lg shadow-white/10 min-w-[200px] mx-auto
                  flex items-center justify-center space-x-2 hover:bg-opacity-90"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-purple-600 opacity-0 
                  group-hover:opacity-100 transition-opacity duration-300 blur-xl" />
                <span className="relative flex items-center justify-center w-full">
                  <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path fill="currentColor" d="M12.24 10.285V14.4h6.806c-.275 1.765-2.056 5.174-6.806 5.174-4.095 0-7.439-3.389-7.439-7.574s3.345-7.574 7.439-7.574c2.33 0 3.891.989 4.785 1.849l3.254-3.138C18.189 1.186 15.479 0 12.24 0c-6.635 0-12 5.365-12 12s5.365 12 12 12c6.926 0 11.52-4.869 11.52-11.726 0-.788-.085-1.39-.189-1.989H12.24z"/>
                  </svg>
                  Get Started
                  <svg 
                    className="ml-2 w-5 h-5 transform transition-transform group-hover:translate-x-1" 
                    fill="none" 
                    stroke="currentColor" 
                    viewBox="0 0 24 24"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                  </svg>
                </span>
              </button>
            </motion.div>
          </motion.div>

          {/* Feature Cards - Improved mobile layout */}
          <motion.div
            initial={{ opacity: 0, y: 40 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.1 }}
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 w-full max-w-7xl 
              px-6 sm:px-8 mb-12 sm:mb-16"
          >
            {FEATURES.map((feature, index) => (
              <FeatureCard key={index} {...feature} index={index} />
            ))}
          </motion.div>
        </main>
      </div>
    </div>
  );
};

const FEATURES = [
  {
    title: "Multi-Agent Collaboration",
    description: "Orchestrate multiple AI agents working together seamlessly on complex tasks",
    icon: (
      <svg className="w-8 h-8" viewBox="0 0 24 24" fill="none" stroke="currentColor">
        <circle cx="12" cy="12" r="3" />
        <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" />
      </svg>
    ),
    gradient: "from-blue-500/20 via-purple-500/20 to-blue-500/20"
  },
  {
    title: "Human-AI Teaming",
    description: "Bridge human expertise with AI capabilities for optimal problem-solving",
    icon: (
      <svg className="w-8 h-8" viewBox="0 0 24 24" fill="none" stroke="currentColor">
        <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5" />
      </svg>
    ),
    gradient: "from-purple-500/20 via-cyan-500/20 to-purple-500/20"
  },
  {
    title: "Purpose-Driven Results",
    description: "Focus AI collaboration on achieving meaningful real-world outcomes",
    icon: (
      <svg className="w-8 h-8" viewBox="0 0 24 24" fill="none" stroke="currentColor">
        <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
      </svg>
    ),
    gradient: "from-cyan-500/20 via-blue-500/20 to-cyan-500/20"
  }
];

const FeatureCard = ({ title, description, icon, gradient, index }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay: 1.1 + index * 0.1 }}
    whileHover={{ y: -5, scale: 1.02 }}
    className="relative p-6 sm:p-6 md:p-8 rounded-xl backdrop-blur-xl bg-white/5 border border-white/10
      hover:bg-white/10 transition-all duration-300 overflow-hidden group
      shadow-lg shadow-black/20 h-full flex flex-col"
  >
    {/* Enhanced gradient background */}
    <div className={`absolute inset-0 bg-gradient-to-r ${gradient} opacity-0 
      group-hover:opacity-100 transition-opacity duration-500 blur-xl`} />
    
    {/* Enhanced Content with better mobile spacing */}
    <div className="relative flex-1 space-y-3 sm:space-y-4">
      <div className="text-blue-400/90 transform group-hover:scale-110 transition-transform duration-300 mb-2">
        {icon}
      </div>
      <h3 className="text-lg sm:text-xl font-bold text-white group-hover:text-blue-300 transition-colors">{title}</h3>
      <p className="text-sm sm:text-base text-gray-300/90 leading-relaxed">{description}</p>
    </div>
    
    {/* Enhanced hover indicator - Improved touch target size */}
    <div className="absolute bottom-4 right-4 opacity-0 group-hover:opacity-100 transition-opacity">
      <svg 
        className="w-6 h-6 text-blue-400/90" 
        fill="none" 
        stroke="currentColor" 
        viewBox="0 0 24 24"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
      </svg>
    </div>
  </motion.div>
);

export default HomePage;