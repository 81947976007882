import {detect} from 'jschardet';

export const truncateString = (str, maxLength) => {
  if (!str) return { truncated: '', full: '' };
  if (str.length <= maxLength) return { truncated: str, full: str };

  const detectedEncoding = detect(str);
  if (![`ascii`, `utf-8`].includes(detectedEncoding.encoding)) {
    return { truncated: str, full: str };
  }

  // Find the last space within maxLength - 3 (to account for '...')
  const lastSpace = str.lastIndexOf(' ', maxLength - 3);
  const truncated = lastSpace > 0 
    ? str.substring(0, lastSpace) + ' ...'
    : str.substring(0, maxLength - 3) + ' ...';

  return { truncated, full: str };
};

export const getTooltipText = (str, maxWords = 20) => {

  if (!str) return '';
  
  // Detect encoding
  const detectedEncoding = detect(str);
  
  if (detectedEncoding.encoding === 'ascii' || detectedEncoding.encoding === 'utf-8') {
    const words = str.split(/\s+/);
    if (words.length <= maxWords) return str;
    return words.slice(0, maxWords).join(' ') + ' ...';
  }
  // If encoding is not ASCII or UTF-8, return the original string
  return str;
};
