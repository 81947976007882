import React, { useRef, useEffect, useState, useCallback, forwardRef } from 'react';
import { debounce } from 'lodash';
import { Eraser, Send, Database, Plus, X, Loader, MessageSquarePlus, Check, FileText, Link, Square, Mic, MicOff } from 'lucide-react';
import getCaretCoordinates from 'textarea-caret';
import DataSourceDialog from './DataSourceDialog';
import ConfirmationDialog from './ConfirmationDialog';
import ErrorPopup from './ErrorPopup';
import { StyledTextarea } from '../utils/styledComponents';
import useVoiceInput from '../hooks/useVoiceInput'; // Import the hook

// Add this function before the InputForm component
const isIPhone = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /iPhone/i.test(userAgent);
};

const InputForm = forwardRef(({
  inputValue,
  setInputValue,
  handleSendMessage,
  suggestions,
  setSuggestions,
  selectedAgents,
  setSelectedAgents,
  activeAgents,
  maxRounds,
  setMaxRounds,
  selectedThreadId,
  handleAddConversationDataSource,
  handleGetConversationData,
  handleRemoveConversationDataSource,
  conversationData,
  isMessageLoading,
  createThread,
  isCreatingNewThread,
  selectedProjectId,
  abortChat,
  messages,
  isAddingAgent,
  quickStart = false,
  onKeyPress,
  disabled,
  setIsSidebarCollapsed,
}, ref) => {
  const inputRef = useRef(null);
  const suggestionsRef = useRef(null);
  const [cursorPos, setCursorPos] = useState({ top: 0, left: 0 });
  const [isDataSourceDialogOpen, setIsDataSourceDialogOpen] = useState(false);
  const [isDataSourcePanelOpen, setIsDataSourcePanelOpen] = useState(false);
  const [isUpdatingConversationDataSource, setIsUpdatingConversationDataSource] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(null);
  const [error, setError] = useState(null);
  const [deletingDataSourceId, setDeletingDataSourceId] = useState(null);
  const [isIPhoneDevice, setIsIPhoneDevice] = useState(false);
  const isSmallScreen = window.innerWidth < 768;
  const conversationLength = messages?.length || 0;
  const [editingMessage, setEditingMessage] = useState(null);

  // Use the useVoiceInput hook directly
  const {
    isListening,
    startListening,
    stopListening,
    error: voiceInputError,
  } = useVoiceInput();

  const resizeTextarea = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      const minHeight = window.innerWidth >= 768 ? '40px' : '20px';
      inputRef.current.style.height = `${Math.max(inputRef.current.scrollHeight, parseInt(minHeight))}px`;
    }
  }, []);

  const debouncedResizeTextarea = useCallback(
    debounce(resizeTextarea, 100),
    [resizeTextarea]
  );

  useEffect(() => {
    debouncedResizeTextarea();
    return () => debouncedResizeTextarea.cancel();
  }, [inputValue, debouncedResizeTextarea]);

  const handleDataSourceIconClick = async () => {
    if (!selectedThreadId) return;
    setIsDataSourcePanelOpen(!isDataSourcePanelOpen);

    // Refresh data sources
    try {
      await handleGetConversationData();
      
    } catch (error) {
      console.error("Error refreshing data sources:", error);
      setError("Failed to refresh data sources. Please try again.");
    }
  };

  const handleAddNewDataSource = () => {
    if (!selectedThreadId) return;
    setIsDataSourceDialogOpen(true);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (inputValue.trim() && !isMessageLoading) {
      if (editingMessage) {
        handleSendMessage(editingMessage.created_at, inputValue.trim())
          .then(() => {
            setEditingMessage(null);
            setInputValue('');
          })
          .catch(error => setError(error.message));
      } else {
        handleSendMessage(null, inputValue.trim())
          .then(() => {
            setInputValue('');
          })
          .catch(error => setError(error.message));
      }
    }
  };

  const handleSendOrAbort = () => {
    if (isMessageLoading) {
      abortChat();
    } else {
      handleFormSubmit({ preventDefault: () => {} });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleFormSubmit(e);
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    debouncedResizeTextarea();

    if (value.includes("@")) {
      const query = value.split("@").pop().toLowerCase();
      const filteredAgents = activeAgents.filter(agent => 
        agent.name.toLowerCase().includes(query)
      );
      setSuggestions(filteredAgents);

      const rect = event.target.getBoundingClientRect();
      const position = event.target.selectionStart;
      const caret = getCaretCoordinates(event.target, position);
      const suggestionBoxHeight = Math.min(filteredAgents.length * 40, 208);
      setCursorPos({
        top: rect.top + caret.top + window.scrollY - suggestionBoxHeight - 10,
        left: rect.left + caret.left + window.scrollX
      });
    } else {
      setSuggestions([]);
    }
  };

  const handleAgentSelection = (agent) => {
    const textarea = inputRef.current;
    if (textarea) {
      const selectionStart = textarea.selectionStart;
      const selectionEnd = textarea.selectionEnd;
      const textBefore = inputValue.substring(0, selectionStart).replace(/@\S*$/, '');
      const textAfter = inputValue.substring(selectionEnd);
      const newValue = `${textBefore}@${agent.name} ${textAfter}`;
      setInputValue(newValue);

      const newCursorPosition = textBefore.length + agent.name.length + 2; // +2 for '@' and space
      setTimeout(() => {
        textarea.setSelectionRange(newCursorPosition, newCursorPosition);
        textarea.focus();
      }, 0);

      if (!selectedAgents.find(a => a.id === agent.id)) {
        setSelectedAgents([...selectedAgents, agent]);
      }
    }
    setSuggestions([]);
  };

  const handleDataSourceSubmit = async (dataSourceRequest) => {
    try {
      setIsUpdatingConversationDataSource(true);
      await handleAddConversationDataSource(dataSourceRequest);
      setIsDataSourceDialogOpen(false);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsUpdatingConversationDataSource(false);
    }
  };

  const handleRemoveDataSource = async (sourceId) => {
    if (!sourceId) {
      setError("Unable to remove data source: Invalid ID");
      return;
    }

    try {
      setDeletingDataSourceId(sourceId);
      await handleRemoveConversationDataSource(sourceId);
    } catch (error) {
      setError(error.message);
    } finally {
      setDeletingDataSourceId(null);
    }
  };

  function formatFileSize(bytes) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  const handleMaxRoundsChange = (e) => {
    const value = Math.max(1, Math.min(10, Number(e.target.value)));
    setMaxRounds(value);
    localStorage.setItem('maxRounds', value.toString());
  };

  useEffect(() => {
    if (editingMessage) {
      setInputValue(editingMessage.content);
      inputRef.current.focus();
    }
  }, [editingMessage]);

  useEffect(() => {
    setIsIPhoneDevice(isIPhone());
  }, []);

  const handleVoiceInput = async () => {
    if (isIPhoneDevice) {
      setError("Voice input is currently not supported on iPhone devices.");
      return;
    }

    if (isListening) {
      // Stop listening and get the audio blob
      const audioBlob = await stopListening();
      if (audioBlob) {
        handleSendMessage(null, null, audioBlob);
      } else {
        console.error('No audio recorded');
      }
    } else {
      startListening();
    }
  };

  useEffect(() => {
    if (voiceInputError) {
      setError(voiceInputError);
    }
  }, [voiceInputError]);

  const isLoading = isMessageLoading || isAddingAgent;

  const handleCancelEdit = () => {
    setEditingMessage(null);
    setInputValue('');
  };

  const handleNewChat = async () => {
    if (selectedProjectId && !isCreatingNewThread && !isLoading) {
      try {
        await createThread();
        setInputValue(''); // Clear input when creating new chat
        setIsSidebarCollapsed(true); // Add this line to collapse the sidebar
      } catch (error) {
        setError(error.message);
      }
    }
  };

  return quickStart ? (
    <div className="relative">
      <form onSubmit={handleFormSubmit} className="flex">
        <textarea
          ref={ref}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleFormSubmit(e);
            }
            if (onKeyPress) onKeyPress(e);
          }}
          placeholder="Type what you want to do..."
          rows={3}
          className="flex-1 p-4 bg-gray-700 text-gray-200 border border-gray-600 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
          disabled={isMessageLoading}
        />
        <button
          type="submit"
          disabled={isMessageLoading || !inputValue.trim()}
          className="absolute right-3 bottom-4 p-2 text-blue-400 hover:text-blue-500 disabled:opacity-50"
        >
          {isMessageLoading ? (
            <Loader className="animate-spin" size={20} />
          ) : (
            <Send size={20} />
          )}
        </button>
      </form>
    </div>
  ) : (
    <>
      {isLoading && isSmallScreen && conversationLength > 1 ? (
        <div className="control-panel">
          <button
            type="button"
            onClick={abortChat}
            className="stop-button"
          >
            <div className="flex items-center gap-2">
              <Square size={16} />
              <span>Stop</span>
            </div>
          </button>
        </div>
      ) : (
        <div className="flex flex-col w-full bg-gray-800 rounded-md">
          <form className="flex flex-col space-y-2 p-4" onKeyDown={handleKeyPress} onSubmit={handleFormSubmit}>
            <div className="flex items-center gap-2">
              <div className="relative flex-grow flex items-center">
                {isListening ? (
                  <div className="w-full h-10 bg-white border border-gray-300 rounded-md px-3 flex items-center">
                    <div className="flex-grow flex justify-center items-center">
                      <div className="audio-wave">
                        {[...Array(5)].map((_, index) => (
                          <div key={index} className="audio-bar"></div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <StyledTextarea
                    ref={inputRef}
                    name="message"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder={editingMessage ? "Edit your message..." : "Type your message..."}
                    className={`w-full bg-gray-700 text-gray-200 border border-gray-600 rounded-md px-3 py-2 focus:outline-none focus:border-gray-500 resize-none overflow-hidden shadow-sm min-h-[40px] ${
                      disabled ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    style={{
                      maxHeight: window.innerWidth >= 768 ? '200px' : '80px',
                      lineHeight: '1.5',
                    }}
                    disabled={disabled}
                  />
                )}
                {suggestions.length > 0 && (
                  <ul
                    ref={suggestionsRef}
                    className="absolute bg-white text-gray-700 border border-gray-300 rounded-md z-10 max-h-40 overflow-auto p-0"
                    style={{ bottom: '100%', left: 0, minWidth: '150px', maxWidth: '300px' }}
                  >
                    {suggestions.map((agent) => (
                      <li
                        key={agent.id}
                        onClick={() => handleAgentSelection(agent)}
                        className="px-2 py-1 cursor-pointer hover:bg-gray-200 list-none flex items-center"
                      >
                        {agent.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {!editingMessage && !isIPhoneDevice && (
                <button
                  type="button"
                  onClick={handleVoiceInput}
                  className={`h-10 w-10 flex items-center justify-center rounded-md ${
                    isListening 
                      ? 'bg-red-500 text-white' 
                      : 'bg-gray-700 text-gray-200 hover:bg-gray-600'
                  } text-sm transition-colors duration-150 ${
                    isLoading ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                  disabled={isLoading}
                >
                  {isListening ? <MicOff size={20} /> : <Mic size={20} />}
                </button>
              )}
              <button
                type="button"
                onClick={handleSendOrAbort}
                className={`h-10 w-10 flex items-center justify-center rounded-md text-sm transition-colors duration-150 ${
                  isMessageLoading 
                    ? 'bg-red-500 text-white hover:bg-red-600 cursor-pointer' 
                    : (activeAgents.length === 0 || !inputValue.trim() || isListening)
                      ? 'bg-gray-700 text-gray-400 cursor-not-allowed'
                      : 'bg-blue-500 text-white hover:bg-blue-600'
                }`}
                disabled={isListening || (!isMessageLoading && (activeAgents.length === 0 || !inputValue.trim()))}
              >
                {isMessageLoading ? <Square size={20} /> : 
                 editingMessage ? <Check size={20} /> : <Send size={20} />}
              </button>
              {editingMessage && (
                <button
                  type="button"
                  onClick={handleCancelEdit}
                  className="w-10 h-10 flex items-center justify-center rounded-md bg-gray-700 text-gray-200 text-sm transition-colors duration-150 hover:bg-gray-600"
                >
                  <X size={20} />
                </button>
              )}
            </div>
            <div className="flex items-center justify-between space-x-2">
              <div className="flex items-center space-x-2">
                <button
                  type="button"
                  className={`w-8 h-8 flex items-center justify-center bg-gray-700 text-sm rounded-md transition-colors duration-150 ${
                    selectedThreadId 
                      ? 'text-gray-200 hover:bg-gray-600' 
                      : 'text-gray-500 opacity-50 cursor-not-allowed'
                  } relative`}
                  onClick={handleDataSourceIconClick}
                >
                  <Database size={16} />
                  {selectedThreadId && conversationData.data_sources && conversationData.data_sources.length > 0 && (
                    <span className="absolute -top-1 -right-1 bg-gray-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center">
                      {conversationData.data_sources.length}
                    </span>
                  )}
                </button>
                <button
                  type="button"
                  className={`w-8 h-8 flex items-center justify-center bg-gray-700 text-sm rounded-md transition-colors duration-150 ${
                    selectedThreadId && !isLoading 
                      ? 'text-gray-200 hover:bg-gray-600' 
                      : 'text-gray-500 opacity-50 cursor-not-allowed'
                  }`}
                  onClick={handleAddNewDataSource}
                  disabled={isUpdatingConversationDataSource || !selectedThreadId || isLoading}
                >
                  <Plus size={16} />
                </button>
              </div>
              <div className="flex items-center space-x-2">
                <div className="flex h-8 bg-gray-700 rounded-md overflow-hidden">
                  <input
                    type="number"
                    value={maxRounds}
                    onChange={handleMaxRoundsChange}
                    min="1"
                    max="10"
                    className="w-10 text-center text-sm bg-transparent text-gray-200 border-none focus:outline-none focus:ring-0"
                    disabled={isMessageLoading}
                  />
                  <div className="px-2 flex items-center justify-center text-sm text-gray-200 border-l border-gray-600">
                    Runs
                  </div>
                </div>
              </div>
            </div>
          </form>
          {isDataSourcePanelOpen && conversationData.data_sources && conversationData.data_sources.length > 0 && (
            <div className="mt-2 bg-white border border-gray-300 rounded-md p-2 shadow-md">
              <h4 className="text-sm font-semibold mb-2"><strong>Data Sources:</strong> <span className="font-normal">({conversationData.data_sources.length})</span></h4>
              <ul className="space-y-1 max-h-44 overflow-y-auto">
                {conversationData.data_sources && conversationData.data_sources.map((source) => (
                  <li key={source.id} className="flex justify-between items-center text-sm text-gray-700">
                    <span className="flex items-center flex-grow mr-2 overflow-hidden">
                      {source.type === 'file' ? (
                        <>
                          <FileText size={16} className="mr-2 flex-shrink-0" />
                          <span className="inline-block max-w-full overflow-hidden text-ellipsis whitespace-nowrap">
                            {source.name} ({formatFileSize(source.size)})
                          </span>
                        </>
                      ) : (
                        <>
                          <Link size={16} className="mr-2 flex-shrink-0" />
                          <span
                            onClick={() => {
                              if (source.url) {  // Change this line
                                window.open(source.url, '_blank', 'noopener,noreferrer');  // Change this line
                              } else {
                                console.error('No URL available for this data source');
                                setError('Unable to open link: No URL available');
                              }
                            }}
                            className={`inline-block max-w-full overflow-hidden text-ellipsis whitespace-nowrap ${
                              source.url ? 'text-blue-600 hover:text-blue-800 underline cursor-pointer' : 'text-gray-500'  // Change this line
                            }`}
                          >
                            {source.name}
                          </span>
                        </>
                      )}
                    </span>
                    <button
                      onClick={() => handleRemoveDataSource(source.id)}
                      className="text-red-500 hover:text-red-700 flex-shrink-0"
                      disabled={deletingDataSourceId === source.id}
                      type="button"
                    >
                      {deletingDataSourceId === source.id ? (
                        <Loader size={16} className="animate-spin" />
                      ) : (
                        <X size={16} />
                      )}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <DataSourceDialog
            isOpen={isDataSourceDialogOpen}
            onClose={() => setIsDataSourceDialogOpen(false)}
            onSubmit={handleDataSourceSubmit}
          />
          {error && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-70 flex justify-center items-center z-50">
              <ErrorPopup message={error} onClose={() => setError(null)} />
            </div>
          )}
          <style jsx>{`
            .audio-wave {
              display: flex;
              align-items: center;
              height: 20px;
            }
            .audio-bar {
              width: 3px;
              height: 100%;
              background-color: #3b82f6;
              margin: 0 2px;
              border-radius: 3px;
              animation: audio-wave 0.5s ease-in-out infinite;
            }
            .audio-bar:nth-child(1) { animation-delay: 0.1s; }
            .audio-bar:nth-child(2) { animation-delay: 0.2s; }
            .audio-bar:nth-child(3) { animation-delay: 0.3s; }
            .audio-bar:nth-child(4) { animation-delay: 0.4s; }
            .audio-bar:nth-child(5) { animation-delay: 0.5s; }
            @keyframes audio-wave {
              0% { transform: scaleY(0.1); }
              50% { transform: scaleY(1); }
              100% { transform: scaleY(0.1); }
            }
          `}</style>
        </div>
      )}
    </>
  );
});

InputForm.displayName = 'InputForm';

export default InputForm;
