import React, { useState, useEffect } from 'react';
import { Plus, Trash2, Save, Loader } from 'lucide-react';
import ErrorPopup from './ErrorPopup';
import ConfirmationDialog from './ConfirmationDialog';
import DataSourceDialog from './DataSourceDialog';
import useEscapeKey from '../hooks/useEscapeKey';
import Modal from './ui/Modal';
import Button from './ui/Button';
import LoadingButton from './ui/LoadingButton';

const adjustTextareaHeight = (element) => {
  element.style.height = 'auto';
  element.style.height = `${element.scrollHeight}px`;
};

const AgentDetails = ({
  isOpen,
  onClose,
  agent,
  createAgent,
  updateAgent,
  isNewAgent,
  isLoading,
  deleteAgent,
  addDataSource,
  removeDataSource,
  resetAgent,
  agentData,
}) => {
  const [agentFormData, setAgentFormData] = useState({
    name: '',
    description: '',
    instructions: '',
    model: 'gpt-4o'
  });
  const [error, setError] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isDeletingAgent, setIsDeletingAgent] = useState(false);
  const [isDataSourceDialogOpen, setIsDataSourceDialogOpen] = useState(false);
  const [isAddingDataSource, setIsAddingDataSource] = useState(false);
  const [removingDataSourceId, setRemovingDataSourceId] = useState(null);

  const supportedModels = [
    {name: 'GPT-4o', value: 'gpt-4o' },
    {name: 'GPT-4o-mini', value: 'gpt-4o-mini' },
  ];

  useEffect(() => {
    if (agent) {
      setAgentFormData({
        name: agent.name || '',
        description: agent.description || '',
        instructions: agent.instructions || '',
        model: agent.model || 'gpt-4o'
      });
    }
  }, [agent]);

  useEffect(() => {
    if (agent) {
      setTimeout(() => {
        document.querySelectorAll('textarea').forEach(textarea => {
          adjustTextareaHeight(textarea);
        });
      }, 0);
    }
  }, [agent, agentFormData]);

  useEscapeKey(onClose, isLoading || isDeletingAgent || isAddingDataSource);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isNewAgent) {
        await createAgent(
          agentFormData.name,
          agentFormData.description,
          agentFormData.instructions,
          agentFormData.model
        );
      } else {
        await updateAgent(
          agent.id,
          agentFormData.name,
          agentFormData.description,
          agentFormData.instructions,
          agentFormData.model
        );
      }
      onClose();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDeleteClick = () => {
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    try {
      setShowDeleteConfirmation(false);
      setIsDeletingAgent(true);
      await deleteAgent(agent.id);
      onClose();
    } catch (error) {
      setError(error.message);
    } finally {
      setIsDeletingAgent(false);
    }
  };

  const handleAddDataSource = async (dataSourceRequest) => {
    try {
      setIsAddingDataSource(true);
      await addDataSource(agent.id, dataSourceRequest);
      setIsDataSourceDialogOpen(false);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsAddingDataSource(false);
    }
  };

  const handleRemoveDataSource = async (dataSourceId) => {
    try {
      setRemovingDataSourceId(dataSourceId);
      await removeDataSource(agent.id, dataSourceId);
    } catch (error) {
      setError(error.message);
    } finally {
      setRemovingDataSourceId(null);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={isNewAgent ? 'New Agent' : 'Agent Details'}
      maxWidth="xl"
      color="indigo"
      disabled={isDeletingAgent}
    >
      <form onSubmit={handleSubmit} className="flex-1 overflow-y-auto">
        <div className="p-6 space-y-6 max-h-[calc(100vh-250px)] overflow-y-auto">
          {/* Agent Name */}
          <div>
            <label className="block text-sm font-medium text-indigo-100 mb-2">
              Agent Name
              {agent?.is_default && (
                <span className="ml-2 text-xs text-indigo-400">Default agent names cannot be changed</span>
              )}
            </label>
            <input
              type="text"
              value={agentFormData.name}
              onChange={(e) => setAgentFormData(prev => ({ ...prev, name: e.target.value }))}
              className="w-full px-4 py-2 bg-gray-700/50 border border-indigo-500/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500/50 text-indigo-100 placeholder-indigo-300/30"
              placeholder="Enter agent name"
              required
              disabled={agent?.is_default}
            />
          </div>

          {/* Description */}
          <div>
            <label className="block text-sm font-medium text-gray-200 mb-2">
              Description
              <span className="ml-1 font-normal text-gray-400">- What is this agent's purpose?</span>
            </label>
            <textarea
              value={agentFormData.description}
              onChange={(e) => {
                setAgentFormData(prev => ({ ...prev, description: e.target.value }));
                adjustTextareaHeight(e.target);
              }}
              className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none text-gray-200"
              placeholder="e.g., A specialized agent for analyzing environmental data"
              rows={2}
            />
          </div>

          {/* Instructions */}
          <div>
            <label className="block text-sm font-medium text-gray-200 mb-2">
              Instructions
              <span className="ml-1 font-normal text-gray-400">- How should this agent behave?</span>
            </label>
            <div className="relative">
              <textarea
                value={agentFormData.instructions}
                onChange={(e) => {
                  setAgentFormData(prev => ({ ...prev, instructions: e.target.value }));
                  adjustTextareaHeight(e.target);
                }}
                className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none text-gray-200"
                placeholder="Provide detailed instructions for the agent's behavior and responsibilities"
                rows={4}
                style={{ maxHeight: '200px', overflowY: 'auto' }}
              />
            </div>
            <p className="mt-1 text-xs text-gray-400">
              Be specific about the agent's role and how it should interact
            </p>
          </div>

          {/* Model Selection */}
          <div>
            <label className="block text-sm font-medium text-gray-200 mb-2">
              Model
              <span className="ml-1 font-normal text-gray-400">- Select AI model to use</span>
            </label>
            <select
              value={agentFormData.model}
              onChange={(e) => setAgentFormData(prev => ({ ...prev, model: e.target.value }))}
              className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-200"
            >
              {supportedModels.map((model) => (
                <option key={model.value} value={model.value}>
                  {model.name}
                </option>
              ))}
            </select>
          </div>

          {/* Data Sources Section */}
          {!isNewAgent && (
            <div>
              <div className="flex justify-between items-center mb-2">
                <label className="text-sm font-medium text-indigo-100">
                  Data Sources
                  <span className="ml-1 font-normal text-indigo-400">- Knowledge base</span>
                </label>
                <Button
                  variant="ghost"
                  onClick={() => setIsDataSourceDialogOpen(true)}
                  icon={<Plus size={20} />}
                />
              </div>
              <div className="space-y-2 max-h-[200px] overflow-y-auto">
                {agentData?.[agent.id]?.data_sources?.length > 0 ? (
                  agentData[agent.id].data_sources.map((source) => (
                    <div key={source.id} className="flex items-center justify-between p-3 bg-gray-700 rounded-lg border border-gray-600">
                      <span className="text-sm text-gray-200 truncate">{source.name}</span>
                      <button
                        type="button"
                        onClick={() => handleRemoveDataSource(source.id)}
                        className="text-gray-400 hover:text-red-400"
                        disabled={removingDataSourceId === source.id}
                      >
                        <LoadingButton
                          variant="ghost"
                          isLoading={removingDataSourceId === source.id}
                          loadingText="Removing..."
                          icon={<Trash2 size={20} />}
                        />
                      </button>
                    </div>
                  ))
                ) : (
                  <div className="text-sm text-gray-400 italic p-3 bg-gray-700 rounded-lg border border-gray-600">
                    No data sources added yet
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="border-t border-indigo-500/20 p-6 flex justify-between items-center bg-gray-800/90">
          {!isNewAgent && (
            <LoadingButton
              variant="danger"
              onClick={handleDeleteClick}
              isLoading={isDeletingAgent}
              loadingText="Deleting..."
              disabled={isLoading || isDeletingAgent}
              icon={<Trash2 size={20} />}
            >
              Delete
            </LoadingButton>
          )}
          <div className="flex gap-3 ml-auto">
            <Button
              variant="secondary"
              onClick={onClose}
              disabled={isDeletingAgent}
            >
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              isLoading={isLoading}
              loadingText={isNewAgent ? "Creating..." : "Saving..."}
              disabled={isLoading}
              icon={!isLoading && <Save size={20} />}
            >
              {isNewAgent ? 'Create' : 'Save'}
            </LoadingButton>
          </div>
        </div>
      </form>

      {/* Dialogs */}
      {showDeleteConfirmation && (
        <ConfirmationDialog
          title="Delete Agent"
          message={`Are you sure you want to delete "${agent.name}"? This action cannot be undone.`}
          onConfirm={handleConfirmDelete}
          onCancel={() => setShowDeleteConfirmation(false)}
          confirmLabel="Delete"
          confirmStyle="danger"
        />
      )}

      {isDataSourceDialogOpen && (
        <DataSourceDialog
          isOpen={isDataSourceDialogOpen}
          onClose={() => setIsDataSourceDialogOpen(false)}
          onSubmit={handleAddDataSource}
          isSubmitting={isAddingDataSource}
        />
      )}

      {error && (
        <ErrorPopup message={error} onClose={() => setError(null)} />
      )}
    </Modal>
  );
};

export default AgentDetails; 