import styled from 'styled-components';

export const StyledTextarea = styled.textarea`
  min-height: 20px; // Default min-height for small screens
  max-height: 120px;
  resize: none;
  overflow-y: auto;
  width: 100%;
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #222;

  @media (min-width: 768px) {
    min-height: 40px; // Min-height for large screens
  }
`;

export const CircuitBackground = styled.div`
  position: absolute;
  inset: 0;
  opacity: 0.05;
  background-image: ${props => `
    linear-gradient(90deg, transparent 0%, transparent 95%, ${props.color || 'rgba(99, 102, 241, 0.3)'} 100%),
    linear-gradient(0deg, transparent 0%, transparent 95%, ${props.color || 'rgba(99, 102, 241, 0.3)'} 100%)
  `};
  background-size: 20px 20px;
  pointer-events: none;
`;
