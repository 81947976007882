import React, { useState } from 'react';
import { LayoutDashboard, User, Menu } from 'lucide-react';

const Header = ({
    isLoggedIn,
    user,
    handleLogOut,
    showDashboard,
    setShowDashboard,
    selectedProjectId,
    projects,
    isSmallScreen,
    isSidebarCollapsed,
    setIsCollapsed,
}) => {
    const [showLogout, setShowLogout] = useState(false);
    
    const currentProject = projects?.find(p => p._id === selectedProjectId);
    const projectName = currentProject?.name || 'Select a Project';
    const userName = user?.name?.split(' ')[0] || user?.name || 'there';

    return (
        <header className={`fixed top-0 left-0 right-0 ${showDashboard ? 'bg-gray-900' : 'bg-gray-800'} text-white w-full p-2 z-50`}>
            <div className="flex justify-between items-center relative">
                {/* Left section */}
                <div className="flex items-center gap-2 flex-shrink-0">
                    {isSmallScreen ? (
                        // Mobile: Show either hamburger or dashboard icon on left
                        <button
                            onClick={showDashboard ? 
                                () => setShowDashboard(false) : 
                                () => setIsCollapsed(!isSidebarCollapsed)
                            }
                            className="p-2 text-gray-200 hover:text-white rounded-lg hover:bg-gray-700"
                            title={showDashboard ? "Go to Workspace" : "Toggle sidebar"}
                        >
                            {showDashboard ? <LayoutDashboard size={20} /> : <Menu size={20} />}
                        </button>
                    ) : (
                        // Desktop: Show hamburger menu on left when in workspace
                        !showDashboard && (
                            <button
                                onClick={() => setIsCollapsed(!isSidebarCollapsed)}
                                className="p-2 text-gray-200 hover:text-white rounded-lg hover:bg-gray-700"
                                title="Toggle sidebar"
                            >
                                <Menu size={20} />
                            </button>
                        )
                    )}
                </div>

                {/* Center section with project name or welcome message */}
                <div className="flex items-center gap-2">
                    {!showDashboard && (
                        <>
                            {/* Show dashboard icon in center only in workspace view */}
                            <button
                                onClick={() => setShowDashboard(true)}
                                className="p-2 text-gray-200 hover:text-white rounded-lg hover:bg-gray-700"
                                title="Go to Dashboard"
                            >
                                <LayoutDashboard size={20} />
                            </button>
                            <div className={`font-semibold text-white ${isSmallScreen ? 'text-sm' : 'text-lg'}`}>
                                {projectName}
                            </div>
                        </>
                    )}
                    {showDashboard && (
                        <div className={`font-semibold text-white ${isSmallScreen ? 'text-sm' : 'text-lg'}`}>
                            Welcome back, {userName}
                        </div>
                    )}
                </div>

                {/* Right section */}
                <div className="relative flex justify-end flex-shrink-0">
                    <button
                        onClick={() => setShowLogout(!showLogout)}
                        className="p-2 flex items-center focus:outline-none"
                    >
                        {user?.picture ? (
                            <img
                                src={user.picture}
                                alt={user.name}
                                className="w-8 h-8 rounded-full"
                            />
                        ) : (
                            <User size={20} className="text-gray-200" />
                        )}
                    </button>
                    {showLogout && (
                        <div className="absolute right-0 mt-2 py-2 w-48 bg-gray-800 border-gray-700 border rounded shadow-xl">
                            <button
                                className="flex items-center px-4 py-2 w-full text-left text-sm text-gray-200 hover:bg-gray-700"
                                onClick={handleLogOut}
                            >
                                <User size={16} className="mr-2" />
                                Logout
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
};

export default Header;