import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/free-solid-svg-icons';

export const SimpleAgentIcon = ({ theme }) => {
  return (
    <div className="relative">
      <motion.div
        initial={{ scale: 0.8 }}
        animate={{ 
          scale: [0.8, 1, 0.8],
          rotate: [0, 2, -2, 0]
        }}
        transition={{ 
          duration: 4,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <FontAwesomeIcon 
          icon={faRobot}
          className={`h-5 w-5 ${theme.accent}`} 
        />
      </motion.div>
      <motion.div
        className={`absolute -top-0.5 -right-0.5 h-1.5 w-1.5 ${theme.ping}`}
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{
          opacity: [0, 0.5, 0],
          scale: [0.5, 1.2, 0.5],
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
          ease: "easeInOut",
          times: [0, 0.5, 1]
        }}
        style={{
          borderRadius: '50%',
          filter: 'blur(0.5px)'
        }}
      />
      <div 
        className={`absolute -top-0.5 -right-0.5 h-1.5 w-1.5 rounded-full ${theme.ping}`}
        style={{ opacity: 0.4 }}
      />
    </div>
  );
}; 