import React from 'react';
import { motion } from 'framer-motion';
import { Loader } from 'lucide-react';

const variants = {
  primary: 'bg-blue-600/90 text-white hover:bg-blue-700',
  secondary: 'bg-gray-700 text-gray-200 hover:bg-gray-600',
  danger: 'bg-red-600/90 text-white hover:bg-red-700',
  ghost: 'text-gray-400 hover:text-gray-200 hover:bg-gray-700/50',
  icon: 'p-1.5 bg-gray-600 rounded-full shadow-sm hover:bg-gray-500 text-gray-200 hover:text-white'
};

const sizes = {
  sm: 'px-3 py-1 text-sm',
  md: 'px-4 py-2',
  lg: 'px-6 py-3 text-lg'
};

const Button = ({ 
  children, 
  variant = 'primary',
  size = 'md',
  isLoading = false,
  disabled = false,
  className = '',
  onClick,
  type = 'button',
  icon,
  ...props 
}) => {
  const baseClassName = `
    rounded-lg transition-colors duration-150
    disabled:opacity-50 disabled:cursor-not-allowed
    flex items-center justify-center gap-2
    font-medium
  `;

  return (
    <motion.button
      type={type}
      onClick={onClick}
      disabled={disabled || isLoading}
      className={`
        ${baseClassName}
        ${variants[variant]}
        ${sizes[size]}
        ${className}
      `}
      whileHover={{ scale: disabled ? 1 : 1.02 }}
      whileTap={{ scale: disabled ? 1 : 0.98 }}
      {...props}
    >
      {isLoading ? (
        <>
          <Loader size={16} className="animate-spin" />
          {children}
        </>
      ) : (
        <>
          {icon}
          {children}
        </>
      )}
    </motion.button>
  );
};

export default Button; 