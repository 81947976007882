import React, { useState, useEffect, useCallback } from 'react';
import { 
  MessageSquare,
  ChevronLeft, 
  ChevronRight,
  Trash2,
  Loader
} from 'lucide-react';
import AgentCard from './cards/AgentCard';
import ProjectCard from './cards/ProjectCard';
import LoadingButton from './ui/LoadingButton';

String.prototype.hashCode = function() {
  let hash = 0;
  for (let i = 0; i < this.length; i++) {
    const char = this.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash;
  }
  return hash;
};

const CarouselRow = ({ 
  title, 
  subtitle, 
  items, 
  onItemSelect, 
  selectedId, 
  onEditItem,
  onShareItem,
  onDeleteItem,
  itemType = 'project',
  isDeletingThread
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (selectedId && itemType === 'project') {
      const selectedIndex = items.findIndex(item => item._id === selectedId);
      if (selectedIndex !== -1) {
        setTimeout(() => {
          const selectedElement = document.querySelector(`[data-project-id="${selectedId}"]`);
          if (selectedElement) {
            selectedElement.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'center'
            });
          }
        }, 300);
      }
    }
  }, [selectedId, items, itemType]);

  const checkScrollButtons = useCallback((container) => {
    if (!container) return;
    
    const hasScrollLeft = container.scrollLeft > 0;
    const hasScrollRight = container.scrollLeft < (container.scrollWidth - container.clientWidth);
    
    setCanScrollLeft(hasScrollLeft);
    setCanScrollRight(hasScrollRight);
  }, []);

  useEffect(() => {
    const container = document.querySelector(`.carousel-container-${itemType}`);
    if (!container) return;

    // Check initial scroll buttons state
    checkScrollButtons(container);

    // Add scroll listener
    const handleScroll = () => checkScrollButtons(container);
    container.addEventListener('scroll', handleScroll);
    
    return () => container.removeEventListener('scroll', handleScroll);
  }, [itemType, checkScrollButtons]);

  const handleScroll = (direction) => {
    const container = document.querySelector(`.carousel-container-${itemType}`);
    if (!container) return;

    if (direction === 'left' && canScrollLeft) {
      container.scrollBy({
        left: -container.offsetWidth / 3,
        behavior: 'smooth'
      });
    } else if (direction === 'right' && canScrollRight) {
      container.scrollBy({
        left: container.offsetWidth / 3,
        behavior: 'smooth'
      });
    }
  };

  const renderItem = (item) => {
    switch (itemType) {
      case 'project':
        return (
          <ProjectCard
            project={item}
            onSelect={onItemSelect}
            onEdit={onEditItem}
            onShare={onShareItem}
            isSelected={selectedId}
          />
        );

      case 'agent':
        return (
          <AgentCard
            agent={item}
            onSelect={onItemSelect}
            onEdit={onEditItem}
          />
        );

      case 'conversation':
        return (
          <button
            onClick={() => onItemSelect(item.projectId, item.id)}
            className={`group relative w-full flex flex-col p-2 pt-1.5 rounded-lg border transition-all h-[60px] w-full
              bg-gray-800 border-gray-600 hover:bg-gray-700`}
          >
            <div className="flex-1 flex items-start gap-2">
              <MessageSquare className="h-5 w-5 text-gray-200 flex-shrink-0 mt-0.5" />
              <div className="flex-1 text-sm text-gray-200 line-clamp-1 pr-4 text-left">
                {item.title || 'Untitled Conversation'}
              </div>
            </div>
            <LoadingButton
              variant="icon"
              onClick={(e) => {
                e.stopPropagation();
                onDeleteItem(item.id);
              }}
              isLoading={isDeletingThread}
              loadingText="Deleting..."
              className="absolute right-1 p-1 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-600 rounded-full shadow-sm hover:bg-gray-500"
              icon={<Trash2 className="h-2.5 w-2.5 text-gray-200 hover:text-red-500" />}
            />
          </button>
        );

      default:
        return null;
    }
  };

  const renderPlaceholder = () => (
    <div className="flex-none w-[calc(33.333%-0.75rem)] h-full">
      <div className="w-full h-full border-2 border-dashed border-gray-200 rounded-xl bg-gray-50/50"></div>
    </div>
  );

  const renderItems = () => {
    const itemElements = items.map((item) => (
      <div 
        key={item._id || item.id}
        data-project-id={item._id}
        className={`flex-none ${
          isSmallScreen 
            ? 'w-[75vw] snap-center'
            : 'w-[calc(33.333%-0.75rem)]'
        }`}
        style={{ 
          minWidth: isSmallScreen ? '75vw' : 'calc(33.333% - 0.75rem)',
          scrollSnapAlign: isSmallScreen ? 'center' : 'none',
          scrollSnapStop: isSmallScreen ? 'always' : 'normal'
        }}
      >
        {renderItem(item)}
      </div>
    ));

    return itemElements;
  };

  // Add useEffect to check for overflow
  useEffect(() => {
    const container = document.querySelector(`.carousel-container-${itemType}`);
    if (!container) return;

    // Check if content is wider than container
    const checkOverflow = () => {
      const hasOverflow = container.scrollWidth > container.clientWidth;
      setCanScrollRight(hasOverflow);
    };

    // Initial check
    checkOverflow();

    // Add resize observer to check for overflow on resize
    const resizeObserver = new ResizeObserver(checkOverflow);
    resizeObserver.observe(container);

    return () => resizeObserver.disconnect();
  }, [itemType, items]);

  // Update showScrollButtons to only check for small screen
  const showScrollButtons = !isSmallScreen;

  return (
    <div className="mb-4">
      <div className="flex flex-col items-center mb-4">
        <div className="flex items-center gap-2">
          <h2 className="text-base sm:text-lg font-semibold text-gray-100">{title}</h2>
        </div>
        {subtitle && (
          <p className="text-xs sm:text-sm text-gray-300 mt-1 text-center w-full">
            {subtitle}
          </p>
        )}
      </div>

      <div className="relative">
        {canScrollLeft && (
          <button
            title="Previous"
            onClick={() => handleScroll('left')}
            className={`absolute left-0 top-1/2 -translate-y-1/2 z-10 p-1.5 ${
              isSmallScreen 
                ? '-ml-2 bg-gray-600 text-gray-200' 
                : '-ml-4 bg-white text-gray-600'
            } rounded-full shadow-lg hover:bg-opacity-90`}
          >
            <ChevronLeft className={`${isSmallScreen ? 'h-3.5 w-3.5' : 'h-4 w-4'}`} />
          </button>
        )}

        <div className={`overflow-x-auto scrollbar-hide carousel-container-${itemType} ${
          isSmallScreen ? 'px-4 -mx-4 bg-gray-700' : 'bg-gray-700'
        }`}>
          <div 
            className={`flex transition-transform duration-300 ease-in-out ${
              isSmallScreen ? 'gap-3 snap-x snap-mandatory' : 'gap-3'
            } ${items.length === 1 ? 'justify-center' : ''}`}
          >
            {items.map((item) => (
              <div 
                key={item._id || item.id}
                data-project-id={item._id}
                className={`flex-none ${
                  isSmallScreen 
                    ? 'w-[85%] snap-center'  // Increased width for better visibility
                    : 'w-[calc(33.333%-0.75rem)]'
                }`}
                style={{ 
                  minWidth: isSmallScreen ? '85%' : 'calc(33.333% - 0.75rem)',
                  scrollSnapAlign: isSmallScreen ? 'center' : 'none',
                  scrollSnapStop: isSmallScreen ? 'always' : 'normal'
                }}
              >
                {renderItem(item)}
              </div>
            ))}
          </div>
        </div>

        {canScrollRight && (
          <button
            title="Next"
            onClick={() => handleScroll('right')}
            className={`absolute right-0 top-1/2 -translate-y-1/2 z-10 p-1.5 ${
              isSmallScreen 
                ? '-mr-2 bg-gray-600 text-gray-200' 
                : '-mr-4 bg-white text-gray-600'
            } rounded-full shadow-lg hover:bg-opacity-90`}
          >
            <ChevronRight className={`${isSmallScreen ? 'h-3.5 w-3.5' : 'h-4 w-4'}`} />
          </button>
        )}
      </div>
    </div>
  );
};

export default CarouselRow; 