import React from 'react';
import { FolderCog2, Edit, Share2 } from 'lucide-react';
import { motion } from 'framer-motion';
import { Users } from 'lucide-react';

const ProjectCard = ({ project, onSelect, onEdit, onShare, isSelected }) => {
  return (
    <button
      onClick={() => onSelect(project._id)}
      className={`group relative w-full flex flex-col p-3 pt-1 rounded-lg border transition-all h-[100px]
        ${project._id === isSelected 
          ? 'bg-blue-500/20 border-blue-500/50 shadow-lg ring-2 ring-blue-500/50'
          : 'bg-gray-800 border-gray-600 hover:bg-gray-700'}`}
    >
      <FolderCog2 className={`h-5 w-5 ${project._id === isSelected ? 'text-blue-400' : 'text-gray-200'}`} />
      <div 
        className="flex-1 flex flex-col justify-center w-full"
        title={project.objective || 'No description'}
      >
        <div className="flex items-center justify-center gap-2">
          <p className={`text-sm font-medium text-center line-clamp-1 ${
            project._id === isSelected ? 'text-blue-200' : 'text-gray-200'
          }`}>
            {project.name}
          </p>
          {project.is_shared && (
            <Users size={14} className="text-blue-400 flex-shrink-0" />
          )}
        </div>
        <p className={`text-xs line-clamp-2 text-center w-[90%] mx-auto mt-1 ${
          project._id === isSelected ? 'text-blue-300/80' : 'text-gray-400'
        }`}>
          {project.objective || project.description || 'No description'}
        </p>
      </div>
      
      <div className="absolute top-1.5 right-2 flex gap-1">
        {onShare && (
          <button
            title="Share Project"
            onClick={(e) => {
              e.stopPropagation();
              onShare(project);
            }}
            className="p-1.5 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-600 rounded-full shadow-sm hover:bg-gray-500"
          >
            <Share2 className="h-3.5 w-3.5 text-gray-200" />
          </button>
        )}
        {onEdit && (
          <button
            title="Edit Project"
            onClick={(e) => {
              e.stopPropagation();
              onEdit(project);
            }}
            className="p-1.5 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-600 rounded-full shadow-sm hover:bg-gray-500"
          >
            <Edit className="h-3.5 w-3.5 text-gray-200" />
          </button>
        )}
      </div>
    </button>
  );
};

export default ProjectCard; 