import { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { handleHttpError } from '../utils/errorHandling';
import { API_BASE_URL } from '../config';

const REDIRECT_URI = process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : 'https://purposemindai.com';

const useApi = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  const api = useMemo(() => {
    const instance = axios.create({
      baseURL: API_BASE_URL,
      withCredentials: true,
    });

    instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response && error.response.status === 401) {
          setIsLoggedIn(false);
          setUser(null);
        }
        handleHttpError(error, 'API request');
      }
    );

    return instance;
  }, []);

  const handleLogOut = useCallback(async () => {
    console.log('Logout initiated');
    try {
      await api.post('/users/logout');
      setIsLoggedIn(false);
      setUser(null);
    } catch (error) {
      console.error('Error during logout:', error);
      setIsLoggedIn(false);
      setUser(null);
    }
  }, [api]);

  const handleSignIn = useCallback(() => {
    console.log('Initiating Google Sign In');
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    if (!clientId) {
      console.error('Google Client ID is not set');
      return;
    }
    
    const authUrl = new URL('https://accounts.google.com/o/oauth2/v2/auth');
    authUrl.searchParams.append('client_id', clientId);
    authUrl.searchParams.append('redirect_uri', REDIRECT_URI);
    authUrl.searchParams.append('response_type', 'code');
    authUrl.searchParams.append('scope', 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email');
    authUrl.searchParams.append('access_type', 'offline');
    authUrl.searchParams.append('prompt', 'consent');

    window.location.href = authUrl.toString();
  }, []);

  const handleAuthCallback = useCallback(async (code) => {
    try {
      const response = await api.post('/users/exchange-code', { code });
      const { user: userData } = response.data;
      setUser(userData);
      setIsLoggedIn(true);
      
      const newUrl = new URL(window.location.href);
      ['code', 'scope', 'authuser', 'prompt', 'error', 'error_description'].forEach(param => {
        newUrl.searchParams.delete(param);
      });
      window.history.replaceState({}, document.title, newUrl.toString());
    } catch (error) {
      console.error('Error in auth callback:', error.response ? error.response.data : error.message);
      handleHttpError(error);
    }
  }, [api]);

  const checkAuth = useCallback(async () => {
    try {
      console.log('Verifying user session');
      const response = await api.get('/users/me');
      const userData = response.data;
      setUser(userData);
      setIsLoggedIn(true);
      console.log('User session verified, user logged in');
    } catch (error) {
      console.error('Error verifying user session:', error);
      setIsLoggedIn(false);
      setUser(null);
    } finally {
      setIsLoading(false);
    }
  }, [api]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    
    if (code) {
      console.log('Auth code found in URL, handling callback');
      handleAuthCallback(code).then(() => {
        setIsLoading(false);
      });
    } else {
      checkAuth();
    }
  }, [handleAuthCallback, checkAuth]);

  return {
    isLoggedIn,
    setIsLoggedIn,
    isLoading,
    user,
    setUser,
    handleSignIn,
    handleLogOut,
    api,
  };
};

export default useApi;
