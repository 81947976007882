import React from 'react';
import ErrorPopup from './ErrorPopup';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      error: null
    };
  }

  static getDerivedStateFromError(error) {
    return { 
      hasError: true,
      error: error
    };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  handleClose = () => {
    this.setState({ 
      hasError: false,
      error: null
    });
    // Optionally reload the page on error dismissal
    // window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          {this.props.children}
          <ErrorPopup
            message={this.state.error?.message || 'An unexpected error occurred'}
            onClose={this.handleClose}
          />
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary; 