// TermsAndConditions.js
import React from 'react';

const TermsAndConditions = ({ onAgree, onDisagree, showButtons = true, onNavigateToPrivacyPolicy }) => {
    return (
        <div className="relative text-gray-100">
            <div className="max-w-none">
                <h2 className="text-3xl font-bold mb-8 text-white">Terms and Conditions</h2>
                
                <section className="mb-8 space-y-3">
                    <h3 className="text-xl font-semibold text-white">1. Acceptance of Terms</h3>
                    <p className="text-gray-300 leading-relaxed">
                        By accessing and using this website, you accept and agree to be bound by the terms
                        and provision of this agreement.
                    </p>
                </section>
                
                <section className="mb-8 space-y-3">
                    <h3 className="text-xl font-semibold text-white">2. Changes to Terms or Services</h3>
                    <p className="text-gray-300 leading-relaxed">
                        We reserve the right, at our sole discretion, to modify or replace these terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the service.
                    </p>
                </section>
                
                <section className="mb-8 space-y-3">
                    <h3 className="text-xl font-semibold text-white">3. Use of Service</h3>
                    <p className="text-gray-300 leading-relaxed">
                        You may use the service only in compliance with these terms and all applicable laws, regulations, and policies. You agree not to misuse the service or help anyone else to do so. Misuse includes, but is not limited to, using the service for any illegal purpose, to transmit any viruses or malware, or to infringe upon the rights of others.
                    </p>
                </section>
                
                <section className="mb-8 space-y-3">
                    <h3 className="text-xl font-semibold text-white">4. Privacy Policy</h3>
                    <p className="text-gray-300 leading-relaxed">
                        We value your privacy and are committed to protecting your personal data. Our Privacy Policy outlines the types of information we collect, how we use and protect that information, and your rights regarding your personal data. By using our service, you consent to the collection and use of your information as described in our Privacy Policy. For more detailed information, please refer to our full{' '}
                        <button 
                            onClick={onNavigateToPrivacyPolicy} 
                            className="text-blue-400 hover:text-blue-300 transition-colors duration-200 hover:underline"
                        >
                            Privacy Policy
                        </button>.
                    </p>
                </section>
                
                <section className="mb-8 space-y-3">
                    <h3 className="text-xl font-semibold text-white">5. Account Security</h3>
                    <p className="text-gray-300 leading-relaxed">
                        You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer or device. You agree to accept responsibility for all activities that occur under your account or password. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.
                    </p>
                </section>
                
                <section className="mb-8 space-y-3">
                    <h3 className="text-xl font-semibold text-white">6. Termination</h3>
                    <p className="text-gray-300 leading-relaxed">
                        We may terminate or suspend your account and bar access to the service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the terms. Upon termination, your right to use the service will immediately cease. If you wish to terminate your account, you may simply discontinue using the service.
                    </p>
                </section>
                
                <section className="mb-8 space-y-3">
                    <h3 className="text-xl font-semibold text-white">7. Limitation of Liability</h3>
                    <p className="text-gray-300 leading-relaxed">
                        To the maximum extent permitted by applicable law, in no event shall we be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the service; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the service; (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our service by any third party; (v) any errors or omissions in any content or for any loss or damage incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available through the service; and/or (vi) the defamatory, offensive, or illegal conduct of any third party.
                    </p>
                </section>
                
                <section className="mb-8 space-y-3">
                    <h3 className="text-xl font-semibold text-white">8. Governing Law</h3>
                    <p className="text-gray-300 leading-relaxed">
                        These terms shall be governed and construed in accordance with the laws of the jurisdiction in which we are based, without regard to its conflict of law provisions. Our failure to enforce any right or provision of these terms will not be considered a waiver of those rights. If any provision of these terms is held to be invalid or unenforceable by a court, the remaining provisions of these terms will remain in effect. These terms constitute the entire agreement between us regarding our service, and supersede and replace any prior agreements we might have between us regarding the service.
                    </p>
                </section>
                
                {showButtons && (
                    <div className="flex justify-end space-x-4 mt-8">
                        <button
                            onClick={onDisagree}
                            className="px-4 py-2 text-sm font-medium text-gray-300 hover:text-white 
                                transition-colors duration-150"
                        >
                            Decline
                        </button>
                        <button
                            onClick={onAgree}
                            className="px-4 py-2 text-sm font-medium bg-blue-600 text-white rounded-lg
                                hover:bg-blue-700 transition-colors duration-150"
                        >
                            Accept
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TermsAndConditions;