import { useEffect } from 'react';

const useEscapeKey = (onEscape, disabled = false) => {
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape' && !disabled) {
        onEscape();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);
    return () => document.removeEventListener('keydown', handleEscapeKey);
  }, [onEscape, disabled]);
};

export default useEscapeKey; 