import { useState, useEffect, useRef, useCallback } from 'react';
import useApi from './useApi';
import { handleHttpError } from '../utils/errorHandling';

const useAutoChat = ({
  selectedProjectId,
  selectedThreadId,
  setMessages,
  setWorkbenchContent,
  activeAgents,
  user
}) => {
  const { api } = useApi();
  // Chat state
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [maxRounds, setMaxRounds] = useState(() => {
    const savedMaxRounds = localStorage.getItem('maxRounds');
    return savedMaxRounds ? parseInt(savedMaxRounds, 10) : 5;
  });
  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const abortControllerRef = useRef(null);

  useEffect(() => {
    localStorage.setItem('maxRounds', maxRounds.toString());
  }, [maxRounds]);

  const streamResponses = async (response) => {
    const reader = response.body.getReader();
    let partialMessage = '';

    const processMessage = (jsonStr) => {
      try {
        const message = JSON.parse(jsonStr);
        const streamingId = message.metadata?.streaming_id;

        setMessages((msgs) => {
          const updatedMessages = [...msgs];
          const existingMessageIndex = updatedMessages.findIndex(m => m.metadata?.streaming_id === streamingId);

          if (existingMessageIndex !== -1) {
            // Update existing message
            updatedMessages[existingMessageIndex] = {
              ...updatedMessages[existingMessageIndex],
              content: updatedMessages[existingMessageIndex].content + message.content,
              metadata: {
                ...updatedMessages[existingMessageIndex].metadata,
                ...message.metadata,
              },
              created_at: updatedMessages[existingMessageIndex].created_at || message.created_at
            };
          } else {
            // Add new message to the end of the array
            updatedMessages.push({
              id: message.id,
              role: message.role,
              content: message.content,
              metadata: {
                ...message.metadata,
                name: message.metadata?.name || '',
                icon: message.metadata?.icon || '',
              },
              created_at: message.created_at
            });
          }

          return updatedMessages;
        });

        // Update workbench content for non-Message artifacts
        if (message.metadata?.artifact_type !== 'Message') {
          setWorkbenchContent((prevContent) => {
            if (!prevContent || prevContent.metadata?.streaming_id !== streamingId) {
              // This is a new artifact
              return {
                id: streamingId,
                content: message.content,
                metadata: {
                  ...message.metadata,
                  icon: message.metadata?.icon || '',  // Add icon to metadata
                },
                created_at: message.created_at
              };
            }
            // This is an existing artifact, append the content
            return {
              ...prevContent,
              content: prevContent.content + message.content,
              metadata: {
                ...prevContent.metadata,
                ...message.metadata,
                icon: message.metadata?.icon || '',
              },
              created_at: prevContent.created_at
            };
          });
        }
      } catch (error) {
        console.error('Error parsing JSON:', error, 'String:', jsonStr);
      }
    };

    const readStream = async () => {
      try {
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            console.log('Stream complete');
            break;
          }

          // Check if abort has been requested
          if (abortControllerRef.current?.signal.aborted) {
            console.log('Stream aborted');
            reader.cancel();
            break;
          }

          const chunk = new TextDecoder("utf-8").decode(value);
          partialMessage += chunk;

          let newlineIndex;
          while ((newlineIndex = partialMessage.indexOf('\n')) !== -1) {
            const line = partialMessage.slice(0, newlineIndex);
            partialMessage = partialMessage.slice(newlineIndex + 1);
            if (line.startsWith('data: ')) {
              processMessage(line.substring(6).trim());
            }
          }

          // Process any remaining partial message
          if (partialMessage.startsWith('data: ')) {
            processMessage(partialMessage.substring(6).trim());
          }
        }
      } catch (error) {
          console.error('Error reading stream:', error);
      }
    };

    await readStream();
    console.log('Stream processing complete');
  };

  const setupAbortController = () => {
    abortControllerRef.current = new AbortController();
    return abortControllerRef.current.signal;
  };
  
  const startAutoChat = async ({ start_message, rounds = maxRounds, offset = null }) => {
    if (!selectedProjectId) {
      handleHttpError(new Error('No project selected'), 'starting chat');
    }

    if (!selectedThreadId) {
      handleHttpError(new Error('No conversation selected'), 'starting chat');
      return;
    }

    try {
      const signal = setupAbortController();
      const response = await fetch(`${api.defaults.baseURL}/conversations/${selectedProjectId}/start-auto-chat`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          conversation_id: selectedThreadId,
          message: { role: 'user', content: start_message },
          temperature: 0.7,
          max_rounds: rounds,
          message_offset_timestamp: Math.floor(offset)
        }),
        credentials: 'include',
        signal: signal
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      await streamResponses(response);

    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Chat aborted');
      } else {
        handleHttpError(error, 'starting auto chat');
      }
    } finally {
      setIsMessageLoading(false);
    }
  };

  const parseAgentMentions = (text = '') => {
    if (!Array.isArray(activeAgents) || !text) {
        return [];
    }

    return activeAgents
      .map(agent => {
        const mention = `@${agent?.name || ''}`.toLowerCase(); // Lowercase the full mention
        const index = text.toLowerCase().indexOf(mention); // Search in lowercased text
        return { agent, index };
      })
      .filter(({ index, agent }) => index !== -1 && agent?.name)
      .sort((a, b) => a.index - b.index)
      .reduce((unique, { agent }) => {
        const name = agent.name.toLowerCase();
        return unique.some(a => a.name.toLowerCase() === name) ? unique : [...unique, agent];
      }, []);
  };

  const handleSendMessage = async (editingMessageTimestamp = null, editedContent = null, audioBlob = null) => {
    if (!selectedProjectId || !selectedThreadId) {
      console.error('No active conversation');
      return;
    }

    let messageContent = editedContent || inputValue.trim();
    setInputValue('');

    if (messageContent || audioBlob) {
      setMessages(msgs => {
        if (editingMessageTimestamp) {
          const editedIndex = msgs.findIndex(msg => msg.created_at === editingMessageTimestamp);
          if (editedIndex !== -1) {
            return [
              ...msgs.slice(0, editedIndex),
              { 
                ...msgs[editedIndex], 
                content: messageContent, 
                edited: true,
                editedAt: Date.now() / 1000
              }
            ];
          }
        }
        // Add temporary ID and user name to user message
        const tempId = `temp_user_${Date.now()}`;
        return [...msgs, { 
          id: tempId,
          role: 'user', 
          content: messageContent,
          created_at: (Date.now() - 1) / 1000,
          metadata: {
            name: user?.name,  // Add user's name
            artifact_type: 'Message',
            ...(audioBlob ? { is_audio: true } : {})
          }
        }];
      });

      setIsMessageLoading(true);
      try {
        if (audioBlob) {
          await handleAudioChat(audioBlob);
        } else {
          const mentionedAgents = parseAgentMentions(messageContent);
          const agentsToInvoke = mentionedAgents.length > 0 ? mentionedAgents : selectedAgents;

          if (agentsToInvoke.length > 0) {
            await invokeAgents({
              agentIds: agentsToInvoke.map(agent => agent.id),
              start_message: messageContent,
              offset: editingMessageTimestamp,
              max_rounds: maxRounds
            });
          } else {
            await startAutoChat({
              start_message: messageContent,
              rounds: maxRounds,
              offset: editingMessageTimestamp
            });
          }
        }
      } finally {
        setIsMessageLoading(false);
        setSelectedAgents([]);
      }
    }
  };

  const invokeAgents = async ({ agentIds, start_message, offset, max_rounds = maxRounds }) => {
    if (!selectedProjectId || !selectedThreadId) {
      handleHttpError(new Error('No project or thread selected'), 'invoking agents');
    }

    try {
      const signal = setupAbortController();
      const payload = {
        conversation_id: selectedThreadId,
        agent_ids: agentIds,
        max_rounds: max_rounds,
        message: { role: 'user', content: start_message },
      };

      if (offset) {
        payload.message_offset_timestamp = Math.floor(offset);
      }

      const response = await fetch(
        `${api.defaults.baseURL}/conversations/${selectedProjectId}/invoke-agents`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
          credentials: 'include',
          signal: signal
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      await streamResponses(response);

    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Agent invocation aborted');
      } else {
        handleHttpError(error, 'invoking agents');
      }
    }
  };

  const abortChat = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      setIsMessageLoading(false);
    }
  };

  const handleAudioChat = useCallback(async (audioBlob) => {
    if (!selectedProjectId || !selectedThreadId) {
      handleHttpError(new Error('No conversation selected'), 'processing audio chat');
    }

    try {
      if (!audioBlob) {
        throw new Error('No audio recorded');
      }

      const formData = new FormData();
      formData.append('audio_file', audioBlob, 'audio.webm');
      formData.append('conversation_id', selectedThreadId);
      formData.append('max_rounds', '1');
      formData.append('message', JSON.stringify({ role: 'user', content: '' }));

      const signal = setupAbortController();
      const response = await fetch(`${api.defaults.baseURL}/conversations/${selectedProjectId}/audio-chat`, {
        method: 'POST',
        body: formData,
        credentials: 'include',
        signal: signal
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      await streamResponses(response);
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Audio chat aborted');
      } else {
        console.error('Error in handleAudioChat:', error);
        setMessages(msgs => [...msgs, { role: 'assistant', content: 'Error processing audio chat. Please try again.' }]);
      }
    }
  }, [selectedProjectId, selectedThreadId, setMessages, streamResponses, api.defaults.baseURL]);
  
  return {
    inputValue,
    setInputValue,
    handleSendMessage,
    suggestions,
    setSuggestions,
    selectedAgents,
    setSelectedAgents,
    maxRounds,
    setMaxRounds,
    isMessageLoading,
    invokeAgents,
    abortChat,
  };
};

export default useAutoChat;
