import React from 'react';

const PrivacyPolicy = ({ onClose, showCloseButton = true }) => {
  return (
    <div className="relative text-gray-100">
      {showCloseButton && (
        <button
          onClick={onClose}
          className="absolute top-0 right-0 p-2 text-gray-400 hover:text-white transition-colors"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      )}

      <div className="max-w-none">
        <h2 className="text-3xl font-bold mb-8 text-white">Privacy Policy</h2>
        
        <section className="mb-8 space-y-3">
          <h3 className="text-xl font-semibold text-white">1. Information We Collect</h3>
          <p className="text-gray-300 leading-relaxed">We collect information that you provide directly to us, including:</p>
          <ul className="list-disc pl-5 text-gray-300 space-y-2">
            <li>Account information</li>
            <li>Usage data</li>
            <li>Communication preferences</li>
          </ul>
        </section>
        
        <section className="mb-8 space-y-3">
          <h3 className="text-xl font-semibold text-white">2. How We Use Your Information</h3>
          <p className="text-gray-300 leading-relaxed">
            We use the information we collect to provide, maintain, and improve our services, to develop new features, and to protect our users. We may also use the information for research purposes to better understand how users interact with our AI systems.
          </p>
        </section>
        
        <section className="mb-8 space-y-3">
          <h3 className="text-xl font-semibold text-white">3. Data Sharing and Disclosure</h3>
          <p className="text-gray-300 leading-relaxed">
            We do not sell your personal information. We may share your information with third-party service providers who perform services on our behalf, such as hosting or analytics. We may also disclose your information if required by law or to protect our rights or the rights of others.
          </p>
        </section>
        
        <section className="mb-8 space-y-3">
          <h3 className="text-xl font-semibold text-white">4. Data Security</h3>
          <p className="text-gray-300 leading-relaxed">
            We implement appropriate technical and organizational measures to protect the security of your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure, so we cannot guarantee absolute security.
          </p>
        </section>
        
        <section className="mb-8 space-y-3">
          <h3 className="text-xl font-semibold text-white">5. Your Rights and Choices</h3>
          <p className="text-gray-300 leading-relaxed">
            Depending on your location, you may have certain rights regarding your personal information, such as the right to access, correct, or delete your data. You can exercise these rights by contacting us.
          </p>
        </section>
        
        <section className="mb-8 space-y-3">
          <h3 className="text-xl font-semibold text-white">6. Changes to This Policy</h3>
          <p className="text-gray-300 leading-relaxed">
            We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page and updating the "last updated" date.
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;