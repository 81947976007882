import { useState, useEffect, useCallback, useRef } from 'react';
import useApi from './useApi';
import { handleHttpError } from '../utils/errorHandling';
import useDataSources from './useDataSources';

const LOAD_MESSAGES_LIMIT = 50;
const INACTIVITY_THRESHOLD = 3* 60 * 60 * 1000; // 3 hours in milliseconds

const useConversations = ({ selectedProjectId }) => {
  const { api } = useApi();
  const { fetchDataSources, addDataSource, removeDataSource } = useDataSources();

  const [threads, setThreads] = useState([]);
  const [selectedThreadId, setSelectedThreadId] = useState(null);
  const [currentThreadTitle, setCurrentThreadTitle] = useState('');
  const [moreThreadsAvailable, setMoreThreadsAvailable] = useState(false);
  const [messages, setMessages] = useState([]);
  const [activeAgents, setActiveAgents] = useState([]);
  const [conversationData, setConversationData] = useState([]);
  const [isUpdatingConversationDataSource, setIsUpdatingConversationDataSource] = useState(false);
  const [isLoadingConversation, setIsLoadingConversation] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [isDeletingThread, setIsDeletingThread] = useState(false);
  const [lastConversationData, setLastConversationData] = useState(null);

  const [isLoadingThreads, setIsLoadingThreads] = useState(true);
  const [isLoadingDataSources, setIsLoadingDataSources] = useState(false);

  const LIMIT = 20;
  const [page, setPage] = useState(0);

  useEffect(() => {
    setThreads([]);
    setSelectedThreadId(null);
    setMessages([]);
    setActiveAgents([]);
  }, [selectedProjectId]);

  useEffect(() => {
    const storedData = localStorage.getItem('lastConversation');
    if (storedData) {
      setLastConversationData(JSON.parse(storedData));
    }
  }, []);

  const fetchThreadsAndMessages = useCallback(async (newPage = 0) => {
    if (!selectedProjectId) return;

    try {
      console.log('Fetching threads and messages for project:', selectedProjectId);
      const response = await api.get(`/conversations/${selectedProjectId}`, {
        params: {
          skip: newPage * LIMIT,
          limit: LIMIT
        }
      });

      const { threads, more_threads_available } = response.data;
      console.log('Fetched threads:', threads);
      setThreads(prevThreads => (newPage === 0 ? threads : [...new Set([...prevThreads, ...threads])]));
      setMoreThreadsAvailable(more_threads_available);
      setPage(newPage);

      if (threads.length > 0) {
        const latestThreadId = threads[0].id;
        setSelectedThreadId(latestThreadId);
        
        if (newPage === 0) {
          const messagesResponse = await api.get(`/conversations/${selectedProjectId}/${latestThreadId}/messages`, { 
            params: { 
                limit: LOAD_MESSAGES_LIMIT
              } 
            }
          );
          setMessages(messagesResponse.data.messages);
        }
      } else {
        setSelectedThreadId(null);
        setMessages([]);
      }
    } catch (error) {
      console.error('Error fetching threads and messages:', error);
      handleHttpError(error, 'fetching threads and messages');
    }
  }, [selectedProjectId, api, LIMIT, selectedThreadId]);

  
  const loadMoreThreads = useCallback(async () => {
    if (!selectedProjectId || !moreThreadsAvailable) return;

    await fetchThreadsAndMessages(page + 1);
  }, [selectedProjectId, moreThreadsAvailable, page, fetchThreadsAndMessages]);

  const createThread = useCallback(async () => {
    if (!selectedProjectId) return;

    try {
      const response = await api.post(`/conversations/${selectedProjectId}/create`);
      const newThreadId = response.data.thread_id;
      
      // Set the thread ID immediately
      setSelectedThreadId(newThreadId);
      
      // Add the new thread to the threads list instead of fetching all threads
      setThreads(prevThreads => [{
        id: newThreadId,
        title: 'Untitled Conversation',
        created_at: Date.now() / 1000
      }, ...prevThreads]);
      
      // Clear messages for new thread
      setMessages([]);
      
      return newThreadId;
    } catch (error) {
      handleHttpError(error, 'creating new conversation');
      return null;
    }
  }, [api, selectedProjectId]);

  const deleteThread = useCallback(async (threadId) => {
    if (!selectedProjectId) return;

    try {
      setIsDeletingThread(true);
      await api.delete(`/conversations/${selectedProjectId}/${threadId}`);

      setThreads(prevThreads => prevThreads.filter(thread => thread.id !== threadId));

      if (selectedThreadId === threadId) {
        if (threads.length > 1) {
          const newSelectedThreadId = threads.find(thread => thread.id !== threadId).id;
          setSelectedThreadId(newSelectedThreadId);
          handleThreadSelect(newSelectedThreadId);
        } else {
          setSelectedThreadId(null);
          setMessages([]);
          setActiveAgents([]);
        }
      }
    } catch (error) {
      handleHttpError(error, 'deleting conversation');
    } finally {
      setIsDeletingThread(false);
    }
  }, [api, selectedProjectId, selectedThreadId, threads]);

  const fetchAgentsForThread = useCallback(async (threadId) => {
    if (!threadId || !selectedProjectId) return;

    try {
      let response = await api.get(`/conversations/${selectedProjectId}/${threadId}/agents`);
      let agents = response.data.agents;
      setActiveAgents(agents);
    } catch (error) {
      handleHttpError(error, 'fetching agents for conversation');
    }
  }, [api, selectedProjectId]);

  const fetchMessages = useCallback(async (projectId, threadId) => {
    if (!threadId || !projectId) return;
    const response = await api.get(`/conversations/${projectId}/${threadId}/messages`, { 
      params: { limit: LOAD_MESSAGES_LIMIT }
    });

    // Check response status and data
    if (!response || !response.data || response.status !== 200) {
      throw new Error('Failed to fetch messages: Invalid response');
    }
    
    // The other promises do have responses, so we can safely set the messages
    setMessages(response.data.messages || []);
    setHasMoreMessages(response.data.has_more || false);
  }, [api, selectedProjectId]);

  const handleThreadSelect = useCallback(async (threadId) => {
    if (threadId === selectedThreadId || !selectedProjectId) return;

    try {
      setIsLoadingConversation(true);
      setSelectedThreadId(threadId);
      setHasMoreMessages(true);

      await Promise.all([
        fetchMessages(selectedProjectId, threadId),
        fetchDataSources(selectedProjectId, threadId, 'conversation'),
        fetchAgentsForThread(threadId)
      ]);

    } catch (error) {
      handleHttpError(error, 'selecting conversation');
    } finally {
      setIsLoadingConversation(false);
    }
  }, [api, selectedProjectId, selectedThreadId, fetchDataSources, fetchAgentsForThread]);

  const loadMoreMessages = useCallback(async () => {
    if (!selectedProjectId || !selectedThreadId || !hasMoreMessages) {
      return false;
    }

    try {
      const oldestMessageId = messages.length > 0 ? messages[0].id : null;
      const response = await api.get(`/conversations/${selectedProjectId}/${selectedThreadId}/messages`, {
        params: {
          before: oldestMessageId,
          limit: LOAD_MESSAGES_LIMIT,
        },
      });

      if (!response.data || response.status !== 200) {
        handleHttpError(new Error('Failed to load more messages'), 'loading more messages');
      }
       
      const { messages: newMessages, has_more } = response.data;
      if (newMessages.length === 0) {
        setHasMoreMessages(false);
        return false;
      }
      setMessages((prevMessages) => [...newMessages, ...prevMessages]);
      setHasMoreMessages(has_more);
      return has_more;
    } catch (error) {
      handleHttpError(error, 'loading more messages');
    }
  }, [api, selectedProjectId, selectedThreadId, messages, hasMoreMessages]);

  const handleGetConversationData = useCallback(async () => {
    if (!selectedThreadId || !selectedProjectId) {
      handleHttpError(new Error('No conversation selected'), 'fetching conversation data');
    }
    
    setIsLoadingDataSources(true);
    try {
      const conversationData = await fetchDataSources(selectedProjectId, selectedThreadId, 'conversation');
      setConversationData(conversationData);
      return conversationData;
    } catch (error) {
      handleHttpError(error, 'fetching conversation data');
    } finally {
      setIsLoadingDataSources(false);
    }
  }, [selectedThreadId, selectedProjectId, fetchDataSources]);

  const handleAddConversationDataSource = async (dataSourceRequest) => {
    if (!selectedThreadId || !selectedProjectId) {
      console.error('No conversation selected');
      return;
    }

    try {
      setIsUpdatingConversationDataSource(true);
      const result = await addDataSource(selectedProjectId, selectedThreadId, "conversation", dataSourceRequest);
      if (result) {
        setConversationData(prevData => ({
          ...prevData,
          data_sources: [...(prevData.data_sources || []), result]
        }));
        return result;
      }
      return null;
    } catch (error) {
      handleHttpError(error, 'adding data source to conversation');
    } finally {
      setIsUpdatingConversationDataSource(false);
    }
  };

  const handleRemoveConversationDataSource = async (dataSourceId) => {
    if (!selectedThreadId || !selectedProjectId) {
      console.error('No conversation selected');
      return;
    }

    try {
      await removeDataSource(selectedProjectId, selectedThreadId, 'conversation', dataSourceId);
      setConversationData(prevData => ({
        ...prevData,
        data_sources: prevData.data_sources.filter(source => source.id !== dataSourceId)
      }));
    } catch (error) {
      console.error('Error removing data source:', error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchDataSourcesEffect = async () => {
      if (selectedThreadId && selectedProjectId) {
        try {
          await handleGetConversationData();
        } catch (error) {
          console.error('Error fetching data sources:', error);
        }
      } else {
        setConversationData([]);
      }
    };

    fetchDataSourcesEffect();
  }, [selectedThreadId, selectedProjectId]);

  const renameThread = useCallback(async (threadId, newTitle) => {
    if (!selectedProjectId || !threadId) return;

    try {
      const response = await api.put(`/conversations/${selectedProjectId}/${threadId}/rename`, { new_title: newTitle });
      if (response.data && response.data.new_title) {
        setThreads(prevThreads => prevThreads.map(thread => 
          thread.id === threadId ? { ...thread, title: response.data.new_title } : thread
        ));
        if (threadId === selectedThreadId) {
          setCurrentThreadTitle(response.data.new_title);
        }
      }
    } catch (error) {
      handleHttpError(error, 'renaming conversation');
    }
  }, [api, selectedProjectId, selectedThreadId]);

  // Add this ref to track if it's the initial load
  const isInitialLoad = useRef(true);

  useEffect(() => {
    const initializeConversation = async () => {
      if (selectedProjectId) {
        setIsLoadingThreads(true);
        setIsLoadingConversation(true);
        try {
          await fetchThreadsAndMessages(0);
        } catch (error) {
          console.error('Error initializing conversation:', error);
        } finally {
          setTimeout(() => {
            setIsLoadingThreads(false);
            setIsLoadingConversation(false);
          }, 500);
        }
      } else {
        setThreads([]);
        setSelectedThreadId(null);
        setMessages([]);
        setActiveAgents([]);
        setPage(0);
        setIsLoadingThreads(false);
      }
    };

    initializeConversation();
  }, [selectedProjectId]);

  const handleAudioChat = useCallback(async (audioBlob) => {
    if (!selectedThreadId || !selectedProjectId) {
      console.error('No conversation or project selected');
      return [];
    }

    console.log('Starting audio chat');

    const formData = new FormData();
    formData.append('audio_file', audioBlob, 'audio.webm');  // Changed file extension to .webm
    formData.append('conversation_id', selectedThreadId);
    formData.append('max_rounds', '1');
    formData.append('message', JSON.stringify({ role: 'user', content: '' }));

    try {
      setIsLoadingConversation(true);
      const response = await api.post(
        `/conversations/${selectedProjectId}/audio-chat`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          responseType: 'text',
        }
      );

      console.log('Audio chat response received');

      const messages = [];
      const lines = response.data.split('\n');
      lines.forEach(line => {
        if (line.startsWith('data: ')) {
          try {
            const jsonData = JSON.parse(line.slice(5));
            messages.push(jsonData);
            setMessages(prevMessages => [...prevMessages, jsonData]);
          } catch (error) {
            console.error('Error parsing JSON:', error);
          }
        }
      });

      return messages;
    } catch (error) {
      console.error('Error in handleAudioChat:', error);
      handleHttpError(error, 'processing audio chat');
      return [];
    } finally {
      setIsLoadingConversation(false);
    }
  }, [api, selectedProjectId, selectedThreadId]);

  // Add this useEffect to fetch threads when component mounts with a lastSelectedProject
  useEffect(() => {
    const initializeConversations = async () => {
      const lastSelectedProject = localStorage.getItem('lastSelectedProject');
      if (lastSelectedProject) {
        try {
          console.log('Fetching initial conversations for last project:', lastSelectedProject);
          const response = await api.get(`/conversations/${lastSelectedProject}`, {
            params: {
              skip: 0,
              limit: LIMIT
            }
          });
          
          const { threads, more_threads_available } = response.data;
          setThreads(threads);
          setMoreThreadsAvailable(more_threads_available);
        } catch (error) {
          console.error('Error fetching initial conversations:', error);
        }
      }
    };

    initializeConversations();
  }, []); // Run only once on mount

  return {
    threads,
    selectedThreadId,
    setSelectedThreadId,
    currentThreadTitle,
    setCurrentThreadTitle,
    moreThreadsAvailable,
    loadMoreThreads,
    createThread,
    deleteThread,
    renameThread,
    messages,
    setMessages,
    handleThreadSelect,
    handleGetConversationData,
    handleAddConversationDataSource,
    handleRemoveConversationDataSource,
    fetchAgentsForThread,
    activeAgents,
    setActiveAgents,
    conversationData,
    isUpdatingConversationDataSource,
    isLoadingConversation,
    loadMoreMessages,
    hasMoreMessages,
    handleAudioChat,
    isDeletingThread,
    isLoadingThreads,
  };
};

export default useConversations;
