import React, { useState } from 'react';
import useEscapeKey from '../hooks/useEscapeKey';
import Button from './ui/Button';
import Modal from './ui/Modal';

const ShareProjectDialog = ({ onShare, onClose, isSharing }) => {
    const [email, setEmail] = useState('');
    useEscapeKey(onClose, isSharing);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await onShare(email.trim());
            onClose();
        } catch (error) {
            onClose();
            throw error;
        }
    };

    return (
        <Modal
            isOpen={true}
            onClose={onClose}
            title="Share Project"
            disabled={isSharing}
        >
            <form onSubmit={handleSubmit}>
                <div className="p-6">
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter email address"
                        className="w-full p-2 text-sm bg-gray-700/50 border border-blue-500/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500/50 text-blue-100 placeholder-blue-300/30"
                        required
                        disabled={isSharing}
                    />
                </div>

                <div className="border-t border-blue-500/20 p-6 flex justify-end space-x-2">
                    <Button
                        variant="secondary"
                        onClick={onClose}
                        disabled={isSharing}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        isLoading={isSharing}
                        disabled={isSharing || !email.trim()}
                    >
                        Share
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

export default ShareProjectDialog;