import React from 'react';
import { Link } from 'react-router-dom';
import TermsAndConditions from '../components/TermsAndConditions';

const TermsPage = () => {
  return (
    <div className="min-h-screen bg-black text-white">
      <div className="container mx-auto px-4 py-8">
        <Link 
          to="/"
          className="inline-flex items-center text-sm text-blue-400 hover:text-blue-300 mb-6 transition-colors"
        >
          <svg 
            className="w-4 h-4 mr-2" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
          Back to Home
        </Link>
        
        <div className="max-w-3xl mx-auto bg-gray-900/50 backdrop-blur-lg rounded-xl p-6 shadow-xl border border-white/5">
          <TermsAndConditions showButtons={false} />
        </div>
      </div>
    </div>
  );
};

export default TermsPage; 