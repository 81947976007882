import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';

const SharedMarkdown = ({ children, className = '' }) => {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm, remarkMath]}
      rehypePlugins={[rehypeKatex]}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '');
          return !inline && match ? (
            <SyntaxHighlighter
              style={tomorrow}
              language={match[1]}
              PreTag="div"
              customStyle={{
                fontSize: '0.875rem',
                fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
                background: '#1f2937', // bg-gray-800
                border: '1px solid #374151', // border-gray-700
                borderRadius: '0.375rem',
              }}
              {...props}
            >
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
          ) : (
            <code className={`font-mono bg-gray-700 text-gray-200 px-1 rounded ${className}`} {...props}>
              {children}
            </code>
          );
        },
        p({ children }) {
          return <p className="font-sans text-sm text-gray-200">{children}</p>;
        },
        a({ node, children, href, ...props }) {
          return (
            <a
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400 hover:text-blue-300 hover:underline font-sans"
              {...props}
            >
              {children}
            </a>
          );
        },
        ul({ children }) {
          return <ul className="list-disc pl-5 text-gray-200 my-2">{children}</ul>;
        },
        ol({ children }) {
          return <ol className="list-decimal pl-5 text-gray-200 my-2">{children}</ol>;
        },
        li({ children }) {
          return <li className="text-gray-200 mb-1 pl-1">{children}</li>;
        },
        h1({ children }) {
          return <h1 className="text-2xl font-bold text-gray-100 mb-4">{children}</h1>;
        },
        h2({ children }) {
          return <h2 className="text-xl font-bold text-gray-100 mb-3">{children}</h2>;
        },
        h3({ children }) {
          return <h3 className="text-lg font-bold text-gray-100 mb-2">{children}</h3>;
        },
        h4({ children }) {
          return <h4 className="text-base font-bold text-gray-100 mb-2">{children}</h4>;
        },
        blockquote({ children }) {
          return <blockquote className="border-l-4 border-gray-600 pl-4 italic text-gray-300">{children}</blockquote>;
        },
        table({ children }) {
          return <table className="min-w-full divide-y divide-gray-700 text-gray-200">{children}</table>;
        },
        th({ children }) {
          return <th className="px-3 py-2 bg-gray-700 text-left text-gray-200">{children}</th>;
        },
        td({ children }) {
          return <td className="px-3 py-2 border-t border-gray-700 text-gray-200">{children}</td>;
        },
        hr() {
          return <hr className="border-gray-700 my-4" />;
        },
        strong({ children }) {
          return <strong className="font-bold text-gray-100">{children}</strong>;
        },
        em({ children }) {
          return <em className="italic text-gray-200">{children}</em>;
        },
      }}
      className={`prose prose-sm max-w-none dark:prose-invert font-sans text-sm text-gray-200 ${className}`}
    >
      {children}
    </ReactMarkdown>
  );
};

export default SharedMarkdown;
