import React from 'react';
import useEscapeKey from '../hooks/useEscapeKey';
import Button from './ui/Button';
import LoadingButton from './ui/LoadingButton';
import Modal from './ui/Modal';

const ConfirmationDialog = ({
    title,
    message,
    onConfirm,
    onCancel,
    confirmText = "Confirm",
    cancelText = "Cancel",
    isLoading = false,
    confirmStyle = "primary",
    loadingText
}) => {
    useEscapeKey(onCancel, isLoading);

    const getLoadingText = () => {
        if (loadingText) return loadingText;
        
        switch (confirmText.toLowerCase()) {
            case 'delete':
                return 'Deleting...';
            case 'remove':
                return 'Removing...';
            case 'save':
                return 'Saving...';
            case 'create':
                return 'Creating...';
            case 'update':
                return 'Updating...';
            default:
                return 'Processing...';
        }
    };

    return (
        <Modal
            isOpen={true}
            onClose={onCancel}
            title={title}
            disabled={isLoading}
        >
            <div className="p-6">
                <p className="text-gray-300 text-base">
                    {message}
                </p>
            </div>

            <div className="border-t border-blue-500/20 p-6 flex justify-end space-x-2">
                <Button
                    variant="secondary"
                    onClick={onCancel}
                    disabled={isLoading}
                >
                    {cancelText}
                </Button>
                <LoadingButton
                    variant={confirmStyle === "danger" ? "danger" : "primary"}
                    onClick={onConfirm}
                    isLoading={isLoading}
                    loadingText={getLoadingText()}
                    disabled={isLoading}
                >
                    {confirmText}
                </LoadingButton>
            </div>
        </Modal>
    );
};

export default ConfirmationDialog;