import { useState, useCallback } from 'react';
import { handleHttpError } from '../utils/errorHandling';

const useDashboard = ({
  selectedProjectId,
  handleSendMessage,
  onSelectProject,
  createProject,
  setShowDashboard,
  handleCreateNewThread,
  setWorkbenchContent,
  setWorkbenchVisible,
  setIsSidebarCollapsed,
}) => {

  const handleQuickStart = useCallback(async (message) => {
    try {
      let projectId = selectedProjectId;
      
      // If no project is selected, create one
      if (!selectedProjectId) {
        const { project } = await createProject("New Project");
        projectId = project._id;
        await onSelectProject(projectId);
        
        // Add small delay to ensure DOM is updated before scrolling
        setTimeout(() => {
          const projectElement = document.querySelector(`[data-project-id="${projectId}"]`);
          if (projectElement) {
            projectElement.scrollIntoView({ 
              behavior: 'smooth',
              block: 'nearest',
              inline: 'center'
            });
          }
        }, 100);
      }
     
      setWorkbenchVisible(false);
      setWorkbenchContent(null);
      
      // Create new thread before sending message
      await handleCreateNewThread();
      
      // Hide dashboard and collapse sidebar before sending message
      setShowDashboard(false);
      setIsSidebarCollapsed(true);
      
      // Send the message without awaiting the response
      handleSendMessage(null, message);
      
    } catch (error) {
      handleHttpError(error, 'quick start');
    }
  }, [selectedProjectId, createProject, onSelectProject, handleCreateNewThread, handleSendMessage, setShowDashboard, setIsSidebarCollapsed]);

  return {
    handleQuickStart,
  };
};

export default useDashboard; 