import React from 'react';
import { Cpu, Wrench, AlertTriangle, Globe, Newspaper, Download, FileText, List, Search, Bot, PlusCircle, RefreshCw, Trash2, Image, Languages, Calculator, Antenna, Cog, Microscope, CheckCircle, Settings, Briefcase, ClipboardList, Book, Presentation } from 'lucide-react';

export const getArtifactIcon = (artifactType, iconMetadata) => {
  switch (artifactType) {
    case 'Plan':
      return <ClipboardList size={14} />;
    case 'ExecutionStep':
      return <Cpu size={14} />;
    case 'ToolOutput':
      return getToolIcon(iconMetadata);
    case 'Error':
      return <AlertTriangle size={14} />;
    default:
      return <FileText size={14} />;
  }
};

const getToolIcon = (iconMetadata) => {
  switch (iconMetadata) {
    case '🌐':
      return <Globe size={14} />;
    case '📰':
      return <Newspaper size={14} />;
    case '📥':
      return <Download size={14} />;
    case '📋':
      return <List size={14} />;
    case '🔍':
      return <Search size={14} />;
    case '🤖':
      return <Bot size={14} />;
    case '➕':
      return <PlusCircle size={14} />;
    case '🔄':
      return <RefreshCw size={14} />;
    case '🗑️':
      return <Trash2 size={14} />;
    case '🖼️':
      return <Image size={14} />;
    case '🌐':
      return <Languages size={14} />;
    case '🧮':
      return <Calculator size={14} />;
    case '📡':
      return <Antenna size={14} />;
    case '⚙️':
      return <Cog size={14} />;
    case '🔬':
      return <Microscope size={14} />;
    case '✅':
      return <CheckCircle size={14} />;
    case '📄':
      return <Book size={14} />;
    case '📊':
      return <Presentation size={14} />;
    default:
      return <Wrench size={14} />;
  }
};

export const getArtifactColors = (artifactType) => {
  switch (artifactType) {
    case 'Plan':
      return { bgColor: 'bg-green-100', textColor: 'text-orange-800' };
    case 'ExecutionStep':
      return { bgColor: 'bg-blue-100', textColor: 'text-orange-800' };
    case 'ToolOutput':
      return { bgColor: 'bg-purple-100', textColor: 'text-purple-800' };
    case 'Error':
      return { bgColor: 'bg-red-100', textColor: 'text-red-800' };
    default:
      return { bgColor: 'bg-gray-100', textColor: 'text-gray-800' };
  }
};

export const getArtifactTypeName = (artifactType) => {
  switch (artifactType) {
    case 'Plan':
      return 'Plan';
    case 'ExecutionStep':
      return 'Execution Step';
    case 'ToolOutput':
      return 'Tool Output';
    case 'Error':
      return 'Error';
    default:
      return 'Artifact';
  }
};
