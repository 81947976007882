import React, { useState, useEffect } from 'react';
import { Plus, Trash2, Save, Loader } from 'lucide-react';
import ErrorPopup from './ErrorPopup';
import ConfirmationDialog from './ConfirmationDialog';
import Modal from './ui/Modal';
import Button from './ui/Button';
import LoadingButton from './ui/LoadingButton';

const adjustTextareaHeight = (element) => {
  element.style.height = 'auto';
  element.style.height = `${element.scrollHeight}px`;
};

const ProjectDetails = ({ 
  isOpen, 
  onClose, 
  project, 
  updateProject,
  isNewProject,
  createProject,
  isLoading,
  deleteProject 
}) => {
  const [projectData, setProjectData] = useState({
    name: '',
    objective: '',
    keyResults: [],
    metrics: []
  });
  const [error, setError] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isDeletingProject, setIsDeletingProject] = useState(false);

  useEffect(() => {
    if (project) {
      setProjectData({
        name: project.name || '',
        objective: project.objective || '',
        keyResults: project.key_results || [],
        metrics: project.metrics || []
      });
    }
  }, [project]);

  useEffect(() => {
    if (project) {
      setTimeout(() => {
        const objectiveTextarea = document.querySelector('.objective-textarea');
        if (objectiveTextarea) {
          adjustTextareaHeight(objectiveTextarea);
        }
        
        document.querySelectorAll('.kr-textarea').forEach(textarea => {
          adjustTextareaHeight(textarea);
        });
        document.querySelectorAll('.metric-textarea').forEach(textarea => {
          adjustTextareaHeight(textarea);
        });
      }, 0);
    }
  }, [project, projectData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isNewProject) {
        await createProject(projectData);
      } else {
        await updateProject(project._id, projectData);
      }
      onClose();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleAddKeyResult = () => {
    setProjectData(prev => ({
      ...prev,
      keyResults: [...prev.keyResults, { description: '', target: '', current: '' }]
    }));
  };

  const handleAddMetric = () => {
    setProjectData(prev => ({
      ...prev,
      metrics: [...prev.metrics, { name: '', target: '', current: '' }]
    }));
  };

  const handleRemoveKeyResult = (index) => {
    setProjectData(prev => ({
      ...prev,
      keyResults: prev.keyResults.filter((_, i) => i !== index)
    }));
  };

  const handleRemoveMetric = (index) => {
    setProjectData(prev => ({
      ...prev,
      metrics: prev.metrics.filter((_, i) => i !== index)
    }));
  };

  const handleDeleteClick = async () => {
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    try {
      setShowDeleteConfirmation(false);
      setIsDeletingProject(true);
      await deleteProject(project._id);
      onClose();
    } catch (error) {
      setError(error.message);
    } finally {
      setIsDeletingProject(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={isNewProject ? 'New Project' : 'Project Details'}
      maxWidth="xl"
      disabled={isDeletingProject}
    >
      <form onSubmit={handleSubmit} className="flex-1 overflow-y-auto">
        <div className="p-6 space-y-6 max-h-[calc(100vh-250px)] overflow-y-auto">
          {/* Project Name */}
          <div>
            <label className="block text-sm font-medium text-blue-100 mb-2">
              Project Name
            </label>
            <input
              type="text"
              value={projectData.name}
              onChange={(e) => setProjectData(prev => ({ ...prev, name: e.target.value }))}
              className="w-full px-4 py-2 bg-gray-700/50 border border-blue-500/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500/50 text-blue-100 placeholder-blue-300/30"
              placeholder="Enter project name"
              required
            />
          </div>

          {/* Objective */}
          <div>
            <label className="block text-sm font-medium text-gray-200 mb-2">
              Objective
              <span className="ml-1 font-normal text-gray-400">- What are you trying to achieve?</span>
            </label>
            <textarea
              value={projectData.objective}
              onChange={(e) => {
                setProjectData(prev => ({ ...prev, objective: e.target.value }));
                adjustTextareaHeight(e.target);
              }}
              className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none text-gray-200"
              placeholder="e.g., Develop and implement biodegradable sachets that reduce environmental impact"
              rows={3}
            />
          </div>

          {/* Key Results */}
          <div>
            <div className="flex justify-between items-center mb-2">
              <label className="text-sm font-medium text-blue-100">
                Key Results
                <span className="ml-1 font-normal text-blue-400">- Measurable outcomes</span>
              </label>
              <Button
                variant="ghost"
                onClick={handleAddKeyResult}
                icon={<Plus size={20} />}
              />
            </div>
            <div className="space-y-3">
              {projectData.keyResults.length === 0 ? (
                <div className="text-sm text-gray-400 italic">
                  Add measurable goals like "Reduce plastic waste by 50% within 6 months"
                </div>
              ) : (
                projectData.keyResults.map((kr, index) => (
                  <div key={index} className="flex gap-2">
                    <textarea
                      value={kr.description}
                      onChange={(e) => {
                        const newKRs = [...projectData.keyResults];
                        newKRs[index] = { ...kr, description: e.target.value };
                        setProjectData(prev => ({ ...prev, keyResults: newKRs }));
                        adjustTextareaHeight(e.target);
                      }}
                      className="flex-1 px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none text-gray-200"
                      placeholder="What's the measurable outcome?"
                      rows={1}
                    />
                    <Button
                      variant="ghost"
                      onClick={() => handleRemoveKeyResult(index)}
                      icon={<Trash2 size={20} />}
                    />
                  </div>
                ))
              )}
            </div>
          </div>

          {/* Metrics */}
          <div>
            <div className="flex justify-between items-center mb-2">
              <label className="text-sm font-medium text-gray-200">
                Metrics
                <span className="ml-1 font-normal text-gray-400">- KPIs to track</span>
              </label>
              <Button
                variant="ghost"
                onClick={handleAddMetric}
                icon={<Plus size={20} />}
              />
            </div>
            <div className="space-y-3">
              {projectData.metrics.length === 0 ? (
                <div className="text-sm text-gray-400 italic">
                  Add metrics like "Monthly plastic reduction (kg)" with target "500"
                </div>
              ) : (
                projectData.metrics.map((metric, index) => (
                  <div key={index} className="flex gap-2">
                    <input
                      value={metric.name}
                      onChange={(e) => {
                        const newMetrics = [...projectData.metrics];
                        newMetrics[index] = { ...metric, name: e.target.value };
                        setProjectData(prev => ({ ...prev, metrics: newMetrics }));
                      }}
                      className="flex-1 px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-200"
                      placeholder="Metric name"
                    />
                    <input
                      value={metric.target}
                      onChange={(e) => {
                        const newMetrics = [...projectData.metrics];
                        newMetrics[index] = { ...metric, target: e.target.value };
                        setProjectData(prev => ({ ...prev, metrics: newMetrics }));
                      }}
                      className="w-24 px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-200"
                      placeholder="Target"
                    />
                    <Button
                      variant="ghost"
                      onClick={() => handleRemoveMetric(index)}
                      icon={<Trash2 size={20} />}
                    />
                  </div>
                ))
              )}
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="border-t border-blue-500/20 p-6 flex justify-between items-center bg-gray-800/90">
          {!isNewProject && (
            <LoadingButton
              variant="danger"
              onClick={handleDeleteClick}
              isLoading={isDeletingProject}
              loadingText="Deleting..."
              disabled={isLoading || isDeletingProject}
              icon={<Trash2 size={20} />}
            >
              Delete
            </LoadingButton>
          )}
          <div className="flex gap-3 ml-auto">
            <Button
              variant="secondary"
              onClick={onClose}
              disabled={isDeletingProject}
            >
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              isLoading={isLoading}
              loadingText={isNewProject ? "Creating..." : "Saving..."}
              disabled={isLoading}
              icon={!isLoading && <Save size={20} />}
            >
              {isNewProject ? 'Create' : 'Save'}
            </LoadingButton>
          </div>
        </div>
      </form>

      {showDeleteConfirmation && (
        <ConfirmationDialog
          title="Delete Project"
          message="Are you sure you want to delete this project? This action cannot be undone."
          onConfirm={handleConfirmDelete}
          onCancel={() => setShowDeleteConfirmation(false)}
          confirmLabel="Delete"
          confirmStyle="danger"
        />
      )}

      {error && (
        <ErrorPopup message={error} onClose={() => setError(null)} />
      )}
    </Modal>
  );
};

export default ProjectDetails; 