import { useState, useEffect, useCallback } from 'react';
import useApi from './useApi';
import { handleHttpError } from '../utils/errorHandling';

const useProjects = () => {
    const [projects, setProjects] = useState([]);
    const [selectedProjectId, setSelectedProjectId] = useState(() => {
        return localStorage.getItem('lastSelectedProject') || null;
    });
    const [isSharingProject, setIsSharing] = useState(false);

    const [projectData, setProjectData] = useState({
        name: '',
        description: '',
        objective: '',
        krs: [],
        metrics: []
    });
    const [isLoading, setIsLoading] = useState(true);
    const { api } = useApi();

    useEffect(() => {
        if (selectedProjectId && !isLoading) {
            fetchProjectSettings(selectedProjectId);
            localStorage.setItem('selectedProjectId', selectedProjectId);
        }
    }, [selectedProjectId, isLoading]);

    const selectProject = useCallback(async (projectId) => {
        setSelectedProjectId(projectId);
        if (projectId) {
            localStorage.setItem('lastSelectedProject', projectId);
        } else {
            localStorage.removeItem('lastSelectedProject');
        }
        
        if (projectId) {
            try {
                const response = await api.get(`/projects/${projectId}`);
                setProjectData(response.data);
            } catch (error) {
                console.error('Error fetching project data:', error);
                handleHttpError(error);
            }
        } else {
            setProjectData(null);
        }
    }, []);

    const fetchProjects = useCallback(async () => {
        try {
            const response = await api.get('/projects/');
            setProjects(response.data.projects);
            console.log('Fetched projects:', response.data.projects);
            return response.data.projects;
        } catch (err) {
            handleHttpError(err, 'fetching projects');
            return [];
        }
    }, [api]);

    const fetchProjectSettings = useCallback(async (projectId) => {
        if (!projectId) return;

        try {
            console.log('Fetching project settings for:', projectId);
            const response = await api.get(`/projects/${projectId}`);
            const { name, description, objective, krs, metrics } = response.data;
            const newProjectData = {
                name: name || '',
                description: description || '',
                objective: objective || '',
                krs: krs || [],
                metrics: metrics || []
            };
            console.log('Received project data:', newProjectData);
            setProjectData(newProjectData);
        } catch (err) {
            handleHttpError(err, 'fetching project settings');
        }
    }, [api]);

    useEffect(() => {
        const initializeProjects = async () => {
            setIsLoading(true);
            try {
                const fetchedProjects = await fetchProjects();
                if (fetchedProjects.length === 0) {
                    console.log('No projects found. Creating default project.');
                    const { project } = await createProject("My First Project");
                    setProjects([project]);
                    setSelectedProjectId(project._id);
                } else {
                    const savedProjectId = localStorage.getItem('selectedProjectId');
                    if (savedProjectId && fetchedProjects.some(p => p._id === savedProjectId)) {
                        setSelectedProjectId(savedProjectId);
                    } else {
                        setSelectedProjectId(fetchedProjects[0]._id);
                    }
                }
            } catch (error) {
                console.error('Error initializing projects:', error);
            } finally {
                setIsLoading(false);
            }
        };

        initializeProjects();
    }, []);

    const updateProject = async (projectId, newSettings) => {
        try {
            console.log('Updating project with:', newSettings);
            const response = await api.put(`/projects/${projectId}`, {
                project_data: newSettings
            });
            console.log('Update response:', response.data);

            setProjectData(prevData => ({
                ...prevData,
                ...newSettings
            }));

            setProjects(prevProjects =>
                prevProjects.map(project =>
                    project._id === projectId
                        ? { ...project, ...newSettings }
                        : project
                )
            );

            console.log('Local state updated:', newSettings);
        } catch (err) {
            handleHttpError(err, 'updating project settings');
        }
    };

    const createProject = async (projectName, projectData = {}) => {
        if (!projectName.trim()) {
            handleHttpError(new Error('Project name cannot be empty'), 'creating project');
        }
        
        try {
            console.log('Creating project:', projectName, projectData);
            const response = await api.post('/projects/create', {
                project_data: {
                    name: projectName,
                    objective: projectData.objective,
                    key_results: projectData.keyResults,
                    metrics: projectData.metrics
                }
            });

            if (!response.data || response.status !== 200) {
                handleHttpError(new Error('Failed to create project'), 'creating project');
            }

            const newProject = response.data.project;
            setProjects(prevProjects => [...prevProjects, newProject]);
            setSelectedProjectId(newProject._id);

            return {
                project: newProject,
                initialConversationId: response.data.initial_conversation_id
            };
        } catch (err) {
            handleHttpError(err, 'creating project');
        }
    };

    const deleteProject = async (projectId) => {
        try {
            console.log('Deleting project:', projectId);
            const response = await api.delete(`/projects/${projectId}`);

            if (!response.data?.success || response.status !== 200) {
                handleHttpError(new Error('Failed to delete project'), 'deleting project');
            }

            setProjects(prevProjects => prevProjects.filter(project => project._id !== projectId));
            
            if (selectedProjectId === projectId) {
                const remainingProjects = projects.filter(project => project._id !== projectId);
                if (remainingProjects.length > 0) {
                    setSelectedProjectId(remainingProjects[0]._id);
                } else {
                    setSelectedProjectId(null);
                }
            }
        } catch (err) {
            handleHttpError(err, 'deleting project');
        }
    };

    const shareProject = async (projectId, sharedUserEmail) => {
        try {
            setIsSharing(true);
            console.log('Sharing project:', projectId, 'with:', sharedUserEmail);
            const response = await api.post(`/projects/${projectId}/share`, {
                shared_user_email: sharedUserEmail
            });
            console.log('Share response:', response.data);
            return response.data.message;
        } catch (err) {
            handleHttpError(err, 'sharing project');
        } finally {
            setIsSharing(false);
        }
    }

    return {
        projects,
        selectedProjectId,
        isSharingProject,
        selectProject,
        fetchProjects,
        createProject,
        updateProject,
        deleteProject,
        shareProject
    };
};

export default useProjects;
