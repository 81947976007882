import React from 'react';
import Button from './ui/Button';

const CookieConsent = ({ isOpen, onAccept, setShowTerms }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed bottom-4 right-4 max-w-sm z-50">
      <div className="bg-gray-800 rounded-lg shadow-xl p-4 border border-gray-700">
        <p className="text-xs text-gray-300 mb-3">
          We use cookies to enhance your experience. By continuing, you agree to our{' '}
          <button
            className="text-blue-400 hover:text-blue-300 underline"
            onClick={() => setShowTerms(true)}
          >
            Terms and Conditions
          </button>
        </p>
        <div className="flex justify-end">
          <Button
            variant="primary"
            size="sm"
            onClick={onAccept}
          >
            Accept
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
