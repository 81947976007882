import React, { useState, useEffect } from 'react';
import { UserPlus, UserMinus, Edit, Loader } from 'lucide-react';
import ErrorPopup from './ErrorPopup';
import AgentDetails from './AgentDetails';
import Button from './ui/Button';
import LoadingButton from './ui/LoadingButton';

const Agents = ({
  activeAgents,
  availableAgents,
  agentData,
  addAgentToConversation,
  updateAgent,
  deleteAgent,
  resetDefaultAgent,
  removeAgentFromConversation,
  createAgent,
  addAgentDataSource,
  removeAgentDataSource,
  refreshAgents,
  isExpanded,
  isAddingAgent,
  setIsAddingAgent,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [editingAgent, setEditingAgent] = useState(null);
  const [error, setError] = useState(null);

  // Sort agents by creation/update time
  const sortAgents = (agents) => {
    return [...agents].sort((a, b) => {
      // Use updated_at if available, fall back to created_at
      const aTime = a.updated_at || a.created_at;
      const bTime = b.updated_at || b.created_at;
      return bTime - aTime; // Sort in descending order (newest first)
    });
  };

  // Filter and sort available agents
  const filteredAvailableAgents = sortAgents(
    availableAgents
      .filter(agent => !activeAgents.some(activeAgent => activeAgent.id === agent.id))
      .filter(agent => 
        searchTerm === '' || 
        agent.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        agent.description?.toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  // Sort active agents
  const sortedActiveAgents = sortAgents(activeAgents);

  useEffect(() => {
    if (isExpanded) {
      refreshAgents();
    }
  }, [isExpanded, refreshAgents]);

  const handleAddAgent = async (agentId) => {
    try {
      setIsAddingAgent(true);
      await addAgentToConversation(agentId);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsAddingAgent(false);
    }
  };

  return (
    <div className="agent-panel space-y-4 bg-gray-700 rounded-md shadow-md p-4">
      <div className="space-y-2">
        {/* Active Agents Section */}
        {sortedActiveAgents.length > 0 && (
          <div className="mb-4">
            <h6 className="text-sm mb-2 text-gray-200">In Conversation</h6>
            <div className="max-h-46 overflow-y-auto">
              <div className="space-y-2">
                {sortedActiveAgents.map((agent) => (
                  <div 
                    key={agent.id} 
                    className="group relative flex flex-col px-3 py-2 bg-blue-500/20 rounded-md transition-all shadow-sm"
                    onDoubleClick={() => {
                      if (!agent.is_default) {
                        setEditingAgent(agent);
                      }
                    }}
                  >
                    <div className="flex-1 min-w-0">
                      <div className="text-sm font-medium text-gray-200 truncate">{agent.name}</div>
                      <div className="text-xs text-gray-400 truncate">{agent.description}</div>
                    </div>
                    <div className="absolute right-2 top-1/2 -translate-y-1/2 flex items-center opacity-0 group-hover:opacity-100 transition-opacity bg-gradient-to-l from-blue-500/20 via-blue-500/20 pl-4">
                      {!agent.is_default && (
                        <Button
                          variant="icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditingAgent(agent);
                          }}
                          icon={<Edit size={16} />}
                          className="text-gray-400 hover:text-blue-500"
                        />
                      )}
                      <LoadingButton
                        variant="icon"
                        onClick={() => removeAgentFromConversation(agent.id)}
                        isLoading={isAddingAgent}
                        loadingText="Removing..."
                        className="text-red-300 hover:text-red-200"
                        icon={<UserMinus size={16} />}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Available Agents Section */}
        <div>
          <h6 className="text-sm mb-2 text-gray-200">Available Agents</h6>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search available agents"
            className="w-full p-2 border border-gray-600 rounded-md text-sm mb-2 bg-gray-700 text-gray-200"
          />
          <div className="max-h-44 overflow-y-auto">
            <div className="space-y-2">
              {filteredAvailableAgents.map((agent) => (
                <div 
                  key={agent.id} 
                  className="group relative flex flex-col px-4 py-2 bg-gray-800/50 hover:bg-gray-600/50 rounded-md transition-all"
                  onDoubleClick={() => {
                    if (!agent.is_default) {
                      setEditingAgent(agent);
                    }
                  }}
                >
                  <div className="flex-1 min-w-0">
                    <div className="text-sm font-medium text-gray-200 truncate">{agent.name}</div>
                    <div className="text-xs text-gray-400 truncate">{agent.description}</div>
                  </div>
                  <div className="absolute right-2 top-1/2 -translate-y-1/2 flex items-center opacity-0 group-hover:opacity-100 transition-opacity bg-gradient-to-l from-gray-800/30 via-gray-800/30 pl-4">
                    {!agent.is_default && (
                      <Button
                        variant="icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditingAgent(agent);
                        }}
                        icon={<Edit size={16} />}
                        className="text-gray-400 hover:text-blue-500"
                      />
                    )}
                    <LoadingButton
                      variant="icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddAgent(agent.id);
                      }}
                      isLoading={isAddingAgent}
                      loadingText="Adding..."
                      className="text-blue-500 hover:text-blue-700"
                      icon={<UserPlus size={16} />}
                    />
                  </div>
                </div>
              ))}
              {filteredAvailableAgents.length === 0 && (
                <div className="text-sm text-gray-500 p-2">
                  No agents found
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {editingAgent && (
        <AgentDetails
          isOpen={!!editingAgent}
          onClose={() => setEditingAgent(null)}
          agent={editingAgent}
          createAgent={createAgent}
          updateAgent={updateAgent}
          isNewAgent={false}
          isLoading={false}
          deleteAgent={deleteAgent}
          addDataSource={addAgentDataSource}
          removeDataSource={removeAgentDataSource}
          resetAgent={resetDefaultAgent}
          agentData={agentData}
        />
      )}

      {error && (
        <ErrorPopup message={error} onClose={() => setError(null)} />
      )}
    </div>
  );
};

export default Agents;
