import React, { useEffect, useRef, useState } from 'react';
import { Bot, Loader2, Edit3, ChevronUp, ExternalLink, Check, X, MessageSquare } from 'lucide-react';
import SharedMarkdown from '../utils/SharedMarkdown';
import Workbench from './Workbench';
import { getArtifactIcon, getArtifactColors, getArtifactTypeName } from '../utils/iconUtils';
import { formatNameWithLink } from '../utils/urlUtils';
import { truncateString, getTooltipText } from '../utils/stringUtils';
import { StyledTextarea } from '../utils/styledComponents';
import ErrorPopup from './ErrorPopup';
import Button from './ui/Button';
import { UserIcon, MessageAgentIcon as AgentIcon } from './ui/AnimatedIcons';

const MessageContainer = ({
  selectedProjectId,
  selectedThreadId,
  messages,
  isMessageLoading,
  isLoadingConversation,
  isSmallScreen,
  setWorkbenchContent,
  workbenchContent,
  handleSendMessage,
  onLoadMoreMessages,
  hasMoreMessages,
  isLoadingThreads,
  workbenchVisible,
  setWorkbenchVisible,
  isAddingAgent,
}) => {
  const scrollRef = useRef(null);
  const [loadingMessage, setLoadingMessage] = useState('Thinking...');
  const [showDateMessage, setShowDateMessage] = useState(null);
  const longPressTimer = useRef(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const prevMessagesRef = useRef(messages);
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editedContent, setEditedContent] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    setWorkbenchVisible(false);
  }, [selectedProjectId, selectedThreadId, setWorkbenchVisible]);

  useEffect(() => {
    if (workbenchContent) {
      setWorkbenchVisible(true);
    }
  }, [workbenchContent, setWorkbenchVisible]);

  useEffect(() => {
    if (isMessageLoading || isAddingAgent) {
      const loadingMessages = ['Thinking...', 'Analyzing...', 'Executing...'];
      let index = 0;
      const intervalId = setInterval(() => {
        setLoadingMessage(loadingMessages[index]);
        index = (index + 1) % loadingMessages.length;
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [isMessageLoading, isAddingAgent]);

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      const previousMessages = prevMessagesRef.current;

      if (messages.length > previousMessages.length) {
        // Check if new messages are added at the bottom
        if (
          messages[messages.length - 1]?.id !== previousMessages[previousMessages.length - 1]?.id
        ) {
          // New message added at the bottom, scroll to bottom
          scrollContainer.scrollTop = scrollContainer.scrollHeight;
        }
        // Do not adjust scroll when messages are added at the top
      }
    }
    prevMessagesRef.current = messages;
  }, [messages]);

  const handleLoadMoreMessages = async () => {
    if (!isLoadingMore && hasMoreMessages) {
      const scrollContainer = scrollRef.current;
      const previousScrollHeight = scrollContainer.scrollHeight;
      const previousScrollTop = scrollContainer.scrollTop;

      setIsLoadingMore(true);
      try {
        await onLoadMoreMessages();

        // After messages are loaded and DOM is updated, adjust scrollTop
        requestAnimationFrame(() => {
          const newScrollHeight = scrollContainer.scrollHeight;
          scrollContainer.scrollTop = previousScrollTop + (newScrollHeight - previousScrollHeight);
        });
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoadingMore(false);
      }
    }
  };

  const handleArtifactClick = (artifact) => {
    try {
      setWorkbenchContent(artifact);
      setWorkbenchVisible(true);
    } catch (error) {
      setError('Failed to open artifact');
    }
  };

  const renderArtifactLink = (artifact) => {
    const artifactType = artifact.metadata?.artifact_type;
    const icon = getArtifactIcon(artifactType, artifact.metadata?.icon);
    const { bgColor, textColor } = getArtifactColors(artifactType);

    const { displayName, url } = formatNameWithLink(artifact.metadata?.name || getArtifactTypeName(artifactType));

    // Truncate the display name and get the full name for tooltip
    const tooltipText = getTooltipText(displayName);
    let {truncated: truncatedDisplayName, full: fullDisplayName} = truncateString(displayName, isSmallScreen ? 36 : 50);

    // For small screen, we want to omit the name of the artifact, just show the icon and arguments
    if (isSmallScreen && truncatedDisplayName) {
      const parts = truncatedDisplayName.split(':');
      if (parts.length > 1) {
        truncatedDisplayName = parts[1];
      }
    }
   
    return (
      <button
        key={artifact.id}
        onClick={() => handleArtifactClick(artifact)}
        className={`flex ${bgColor} ${textColor} px-2 py-1 rounded-md mr-2 mb-2 hover:opacity-80 transition-opacity duration-150 text-xs shadow-sm text-left`}
        title={tooltipText}
      >
        <span className="mr-1">{icon}</span>  
        <span className="font-medium">{truncatedDisplayName}</span>
        {url && (
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="ml-1 text-blue-600 hover:text-blue-800"
            onClick={(e) => e.stopPropagation()}
          >
            <ExternalLink size={12} />
          </a>
        )}
      </button>
    );
  };

  const handleMessagePress = (messageId) => {
    longPressTimer.current = setTimeout(() => {
      setShowDateMessage(messageId);
    }, 500); // Show date after 500ms of pressing
  };

  const handleMessageRelease = () => {
    clearTimeout(longPressTimer.current);
    setShowDateMessage(null);
  };

  const renderInPlaceEditing = (message) => {
    return (
      <div className="flex flex-col w-full bg-gray-800">
        <StyledTextarea
          value={editedContent}
          onChange={(e) => setEditedContent(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleEditSave(message);
            }
          }}
          className="w-full p-2 border rounded-md"
          rows={2}
          disabled={isMessageLoading}
        />
        <div className="flex justify-end mt-2">
          <button
            onClick={() => handleEditSave(message)}
            className="text-green-500 hover:text-green-700 mr-2"
            disabled={isMessageLoading}
          >
            <Check size={16} />
          </button>
          <button
            onClick={handleEditCancel}
            className="text-red-500 hover:text-red-700"
            disabled={isMessageLoading}
          >
            <X size={16} />
          </button>
        </div>
      </div>
    );
  };

  const renderMessage = (message, index, artifacts = []) => {
    const isUser = message.role === 'user';
    const isMessage = message.metadata?.artifact_type === 'Message' || isUser;

    if (!isMessage) {
      return null;
    }

    const userMessages = messages.filter(msg => msg.role === 'user');
    const lastFiveUserMessages = userMessages.slice(-5);
    const isEditable = isUser && lastFiveUserMessages.includes(message);

    // Convert message content to string if it's not already
    const messageContent = typeof message.content === 'string' ? message.content : String(message.content);

    return (
      <div
        key={message.id || index}
        className={`my-2 ${isUser ? 'user-message flex justify-end' : 'assistant-message flex justify-start'}`}
        onMouseDown={() => handleMessagePress(message.id)}
        onMouseUp={handleMessageRelease}
        onMouseLeave={handleMessageRelease}
        onTouchStart={() => handleMessagePress(message.id)}
        onTouchEnd={handleMessageRelease}
      >
        <div className={`flex flex-col ${isUser ? 'items-end' : 'items-start'} flex-grow`}>
          {message.metadata?.name && (
            <div className={`flex items-center gap-2 mb-2 ${isUser ? 'flex-row-reverse' : 'flex-row'}`}>
              <div className="flex items-center gap-2">
                {isUser ? <UserIcon /> : <AgentIcon />}
                <span className="text-sm font-medium text-gray-300">
                  {message.metadata.name}
                </span>
              </div>
            </div>
          )}
          <div 
            className={`p-2 rounded-md shadow-sm font-sans text-sm ${
              isUser 
                ? 'user-message bg-gradient-to-br from-blue-500/10 to-blue-500/5 text-gray-200' 
                : 'assistant-message bg-gray-700 text-gray-200'
            } ${
              isUser && editingMessageId !== message.id ? 'w-fit' : 'w-full'
            }`}
          >
            {editingMessageId === message.id ? (
              renderInPlaceEditing(message)
            ) : (
              <SharedMarkdown>{messageContent}</SharedMarkdown>
            )}
            {artifacts.length > 0 && !isUser && (
              <div className="flex flex-wrap mt-2">
                {artifacts.map(renderArtifactLink)}
              </div>
            )}
          </div>
          {isEditable && editingMessageId !== message.id && (
            <button
              onClick={() => handleEditClick(message)}
              className="text-gray-400 hover:text-gray-200 ml-2 mt-1"
              disabled={isMessageLoading}
            >
              <Edit3 size={15} />
            </button>
          )}
          {showDateMessage === message.id && (
            <span className="text-xs mt-1 text-gray-400">
              {new Date(message.edited ? message.editedAt * 1000 : message.created_at * 1000).toLocaleString()}
              {message.edited && " (edited)"}
            </span>
          )}
        </div>
      </div>
    );
  };

  const renderMessages = () => {
    const renderedContent = [];
    let currentArtifacts = [];

    messages.forEach((message, index) => {
      if (message.role === 'user' || message.metadata?.artifact_type === 'Message') {
        const messageElement = renderMessage(message, index, currentArtifacts);
        renderedContent.push(messageElement);
        currentArtifacts = [];
      } else {
        currentArtifacts.push(message);
      }
    });

    if (currentArtifacts.length > 0) {
      renderedContent.push(
        <div key="final-artifacts" className="assistant-message flex items-start my-4">
          <div className="flex-shrink-0 mr-3">
            <div className="w-8 h-8 rounded-full flex items-center justify-center bg-green-500">
              <Bot size={18} className="text-white" />
            </div>
          </div>
          <div className="flex flex-wrap">
            {currentArtifacts.map(renderArtifactLink)}
          </div>
        </div>
      );
    }

    return renderedContent;
  };

  const renderLoadingIndicator = () => (
    <div className="flex items-center space-x-2 text-sm my-2 ml-2 text-gray-400">
      <Loader2 className="h-4 w-4 animate-spin" />
      <span>{loadingMessage}</span>
    </div>
  );

  if (!selectedProjectId || !selectedThreadId) {
    return (
      <div className="flex flex-col h-full items-center justify-center text-gray-400 animate-fade-in">
        <div className="mb-6">
          <MessageSquare 
            className="w-16 h-16 text-blue-400 animate-bounce" 
            strokeWidth={1.5} 
          />
        </div>
        <p className="text-lg font-medium mb-2">Start a New Conversation</p>
        <p className="text-sm text-center max-w-md">
          Select a project and create a new conversation to begin collaborating with AI
        </p>
      </div>
    );
  }

  if (isLoadingConversation || isLoadingThreads) {
    return (
      <div className="flex flex-col h-full items-center justify-center animate-fade-in">
        <div className="relative">
          <Bot size={48} className="text-emerald-500 animate-pulse" />
          <div className="absolute -top-1 -right-1">
            <div className="w-3 h-3 bg-emerald-500 rounded-full animate-ping" />
          </div>
        </div>
        <p className="mt-6 text-gray-300 font-medium">
          {isLoadingThreads ? 'Loading your conversations...' : 'Loading conversation...'}
        </p>
        <div className="mt-2 flex space-x-1">
          <span className="w-2 h-2 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '0ms' }} />
          <span className="w-2 h-2 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '150ms' }} />
          <span className="w-2 h-2 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '300ms' }} />
        </div>
      </div>
    );
  }
  
  const handleEditClick = (message) => {
    setEditingMessageId(message.id);
    setEditedContent(message.content);
  };

  const handleEditCancel = () => {
    setEditingMessageId(null);
    setEditedContent('');
  };

  const handleEditSave = async (message) => {
    if (isMessageLoading) return;
    
    // Clear edit state immediately to prevent UI issues
    const savedContent = editedContent;
    setEditingMessageId(null);
    setEditedContent('');

    try {
      await handleSendMessage(message.created_at, savedContent);
    } catch (error) {
      // If server request fails, show error and restore the edit state
      console.error('Error saving edited message:', error);
      setError('Failed to save edit. Please try again.');
      setEditingMessageId(message.id);
      setEditedContent(savedContent);
    }
  };


  return (
    <div className="flex-1 flex flex-col lg:flex-row overflow-hidden bg-gray-800 relative h-[calc(100vh-200px)]">
      {/* Messages section */}
      <div 
        className={`flex-grow overflow-y-auto ${
          workbenchVisible ? 'lg:w-[60%] border-r border-gray-700' : 'w-full'
        }`} 
        ref={scrollRef}
      >
        {/* Load more button */}
        {hasMoreMessages && messages.length > 5 && (
          <div className="flex justify-center my-4">
            <Button
              variant="ghost"
              onClick={handleLoadMoreMessages}
              disabled={isLoadingMore}
              icon={isLoadingMore ? 
                <Loader2 className="h-4 w-4 animate-spin mr-2" /> : 
                <ChevronUp className="h-4 w-4 mr-1" />
              }
              className="text-xs font-medium text-gray-400 hover:text-gray-200"
            >
              {isLoadingMore ? 'Loading...' : 'Load More'}
            </Button>
          </div>
        )}

        {/* Messages container */}
        <div className="px-4">
          {renderMessages()}
          {(isMessageLoading || isAddingAgent) && renderLoadingIndicator()}
        </div>
      </div>

      {/* Workbench section */}
      {workbenchVisible && workbenchContent && (
        <div className={`
          ${isSmallScreen ? 'h-1/2' : 'lg:w-[40%] h-full'}
          flex-shrink-0 bg-gray-800 border-l border-gray-700
        `}>
          <div className="h-full p-4 bg-gray-800">
            <Workbench
              artifact={workbenchContent}
              isSmallScreen={isSmallScreen}
              onClose={() => {
                setWorkbenchVisible(false);
                setWorkbenchContent(null);
              }}
            />
          </div>
        </div>
      )}
      {error && <ErrorPopup message={error} onClose={() => setError(null)} />}
    </div>
  );
};

export default MessageContainer;
